import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService, WhitelabelService, AppService } from '@app/services';
import { untilDestroyed } from '@app/core';
import { User } from '@app/models';

@Component({
  selector: 'app-configurations-index',
  templateUrl: './configurations-index.component.html',
  styleUrls: ['./configurations-index.component.scss']
})
export class ConfigurationsIndexComponent implements OnInit, OnDestroy {
  currentUser: User;
  page = {
    title: '',
    pathIndex: '',
    path: ''
  };

  constructor(public whitelabelService: WhitelabelService, public appService: AppService, private location: Location, private userService: UserService) {
    location.onUrlChange(x => this.urlChange(x));
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.setCurrent();
  }

  ngOnDestroy() {}

  setCurrent() {
    const page = this.location.path().split('/')[1];

    this.page.pathIndex = page;
    this.page.path = this.location.path();
    this.setCurrentTitle(page);
  }

  setCurrentTitle(url: string) {
    if (url === 'documentos') {
      if (this.location.path().split('/')[2] === 'todos') {
        this.page.title = 'Documentos';
      }
      if (this.location.path().split('/')[2] === 'arquivados') {
        this.page.title = 'Pastas';
      }
    }
    if (url === 'perfil') {
      this.page.title = 'Configurações';
    }
  }

  urlChange(x: string) {
    this.setCurrent();
  }

  showPlanBannerOn(pathArray: string[]) {
    return pathArray.includes(this.page.path);
  }
}
