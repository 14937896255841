import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CredentialsService } from '@app/core/credentials.service';
import { Logger } from '@app/core/logger.service';
import { GraphQLResponse } from '@app/models';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private lastReloadTime: number;

  constructor(private router: Router, private credentialsService: CredentialsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  private errorHandler(errorData: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!errorData || ![404].includes(errorData.status)) {
      log.debug('Request error', errorData);
    }

    if (errorData) {
      if ([401, 429].includes(errorData.status)) {
        // Can't run the logout request since it would be looping HttpClient. But it's not necessary anyway, so it just clears the credentials
        this.credentialsService.clearCredentials();

        if (/^\/(documentos|d|assinar|s)\/[A-z0-9]{25}/.test(location.pathname)) {
          if (!this.reload()) {
            this.router.navigate(['/entrar'], { replaceUrl: true });
          }
        } else {
          this.router.navigate(['/entrar'], { replaceUrl: true });
        }
      } else if (errorData.status === 423) {
        this.credentialsService.clearCredentials();
        this.router.navigate(['/423'], { replaceUrl: true });
      } else if (errorData.status === 503) {
        this.router.navigate(['/503'], { replaceUrl: true });
      }
    }

    if (errorData && [401, 423, 503].includes(errorData.status)) {
      return of();
    } else {
      // Throws error with similar structure as the success request, to use ErrorHandlerService
      throw { data: null, errors: errorData ? [errorData.error] : [], status: errorData ? errorData.status : -1 } as GraphQLResponse;
    }
  }

  private reload() {
    if (!this.lastReloadTime || Date.now() - this.lastReloadTime > 10000) {
      // Can only reload once every 10 seconds to avoid infinite loop
      this.lastReloadTime = Date.now();
      const currentUrl = this.router.url;

      // Reloads component, instead of full reload like location.reload()
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigateByUrl(currentUrl));
      return true;
    }
    return false;
  }
}
