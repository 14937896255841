import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnInit {
  buttonType = 'btn-filled';
  buttonTypeVariant = '';
  buttonState = '';
  buttonSize = 'btn-L';
  iconStart = '';
  iconEnd = '';
  hasText = true;
  buttonClassShow = 'btn-L btn-filled';
  buttonClass = 'btn-L btn-filled';
  isDisabled = false;

  constructor() {}

  ngOnInit() {}

  updateButton() {
    let baseClass = `${this.buttonSize} ${this.buttonType} ${this.buttonTypeVariant}`;

    if (!this.hasText && (this.iconStart || this.iconEnd)) {
      baseClass += ' icon-only';
    } else if (this.iconStart && this.iconEnd) {
      baseClass += ' icon-both';
    } else if (this.iconStart) {
      baseClass += ' icon-start';
    } else if (this.iconEnd) {
      baseClass += ' icon-end';
    }

    this.isDisabled = this.buttonState === 'state-disabled';
    this.buttonClass = baseClass;
    this.buttonClassShow = baseClass + this.buttonState;
  }
}
