<app-mobile-header-menu menulink="/menu/documentos"></app-mobile-header-menu>
<aside class="sidebar">
  <!-- Always -->
  <header class="sidebar__header">
    <a routerLink="/" class="sidebar__header-brand">
      <img [src]="whitelabelService.whitelabel?.pdf_logo_mini" alt="" *ngIf="whitelabelService.whitelabel?.pdf_logo_mini" />
    </a>
    <a href="javascript:void(0)" class="sidebar__header-back" (click)="goBack()">{{ 'button.back' | translate }}</a>
  </header>
  <form class="form">
    <h2 class="form__title">{{ 'model.fillOutTheForm' | translate }}</h2>
    <!-- <p class="form__description">O formulário será preenchido pelo criador do documento antes de enviar o documento para assinatura</p> -->
    <div class="form__container" *ngIf="isRendered">
      <ng-container *ngFor="let variable of attributeInputs">
        <div class="form__field" [attr.data-position]="variable.position">
          <label class="field__holder {{ variable.type == 'longtext' ? 'textarea' : '' }}">
            <textarea class="field empty" name="{{ variable.name }}" *ngIf="variable.type === 'longtext'" (keyup)="handleChangeFillVarible($event)" [required]="variable.required"></textarea>

            <input class="field empty" type="text" name="{{ variable.name }}" *ngIf="variable.type === 'text'" (keyup)="handleChangeFillVarible($event)" [required]="variable.required" />
            <input
              class="field empty"
              type="email"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'email'"
              (keyup)="handleChangeFillVarible($event)"
              (keyup)="handleChangeForValidation($event, 'email')"
              [required]="variable.required"
            />
            <input class="field empty" type="tel" name="{{ variable.name }}" *ngIf="variable.type === 'number'" (keyup)="handleChangeFillVarible($event)" [required]="variable.required" />

            <input
              class="field empty"
              type="tel"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'cpf'"
              mask="000.000.000-00"
              (keyup)="handleChangeFillVarible($event)"
              (keyup)="handleChangeForValidation($event, 'cpf')"
              [required]="variable.required"
            />
            <input
              class="field empty"
              type="tel"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'cnpj'"
              mask="00.000.000/0000-00"
              (keyup)="handleChangeFillVarible($event)"
              (keyup)="handleChangeForValidation($event, 'cnpj')"
              [required]="variable.required"
            />
            <input
              class="field empty"
              type="tel"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'cep'"
              mask="00000-000"
              (keyup)="handleChangeFillVarible($event)"
              [required]="variable.required"
            />
            <input
              class="field empty"
              type="tel"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'phone'"
              mask="(00) 00000-0000"
              (keyup)="handleChangeFillVarible($event)"
              [required]="variable.required"
            />
            <input
              class="field empty"
              type="tel"
              name="{{ variable.name }}"
              *ngIf="variable.type === 'date'"
              mask="00/00/0000"
              (keyup)="handleChangeFillVarible($event)"
              [required]="variable.required"
            />
            <input class="field empty" type="tel" name="{{ variable.name }}" *ngIf="variable.type === 'hour'" mask="00:00" (keyup)="handleChangeFillVarible($event)" [required]="variable.required" />

            <span class="field__placeholder">{{ variable.name }}{{ variable.required ? ' *' : '' }}</span>
          </label>
        </div>
      </ng-container>
      <div class="d-flex mt-2">
        <div class="autentique-button autentique-button--back mr-auto" (click)="goBack()">{{ 'button.back' | translate }}</div>
        <div class="autentique-button ml-auto" (click)="printTemplate()">{{ 'button.forward' | translate }}</div>
      </div>
    </div>
    <div class="form__container" *ngIf="!isRendered">
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
      <div class="rendering rendering__form-field"></div>
    </div>
  </form>
</aside>

<section class="template" id="tinymce__root" [class.d-none]="!isRendered">
  <div class="template__head">
    <div class="template__header-toolbox d-none"></div>
    <div class="template__head-container">
      <h1 class="template__head-title">{{ templateInput.name }}</h1>
      <p class="template__head-descr">
        {{ 'documents.createdOn' | translate: { date: this.templateInput.created_at | dateTime: 'mediumDate' } }}
        <span *ngIf="this.templateInput.created_at !== this.templateInput.updated_at">
          , {{ 'documents.updatedOn' | translate: { date: this.templateInput.updated_at | dateTime: 'mediumDate' } | lowercase }}
        </span>
      </p>
      <div *ngIf="currentUser?.currentPermissions.actions_templates_gr" ngbDropdown display="dynamic" placement="bottom-right" class="template__head-dropdown non-mobile">
        <label id="template__head-dropdown" class="template__head-dropdown-label" ngbDropdownToggle><i class="fal fa-lg fa-ellipsis-v"></i></label>
        <div aria-labelledby="template__head-dropdown" ngbDropdownMenu>
          <button ngbDropdownItem (click)="editTemplate()"><i class="fal fa-edit"></i>{{ 'button.edit' | translate }}</button>
          <button ngbDropdownItem (click)="deleteTemplate()"><i class="fal fa-trash"></i>{{ 'button.exclude' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="template__wrapper">
    <div class="template__wrapper-paper">
      <div id="tinymce"></div>
    </div>
  </div>
</section>

<section class="template" *ngIf="!isRendered">
  <div class="template__head">
    <div class="template__head-container">
      <h1 class="template__head-title rendering">&nbsp;</h1>
      <p class="template__head-descr rendering">&nbsp;</p>
    </div>
  </div>
  <div class="template__wrapper rendering">
    <div class="template__wrapper-paper"></div>
  </div>
</section>

<!-- <section class="template" id="tinymce__root">
  <div class="template__header">
    <div class="template__header-container">
      <h2 class="template__header-title"><b>Modelo:</b> {{ templateInput.name }}</h2>
      <div class="template__header-toolbox d-none"></div>
      <div class="autentique-button ml-auto mr-2" (click)="printTemplate()">Continuar</div>
      <div
        *ngIf="currentUser?.currentPermissions.actions_templates_gr"
        ngbDropdown
        display="dynamic"
        placement="bottom-right"
        class="template__header-dropdown non-mobile"
      >
        <label id="template__header-dropdown" class="template__header-dropdown-label" ngbDropdownToggle><i class="fal fa-lg fa-ellipsis-v"></i></label>
        <div aria-labelledby="template__header-dropdown" ngbDropdownMenu>
          <button ngbDropdownItem (click)="editTemplate()"><i class="fal fa-edit"></i>{{ 'button.edit' | translate }}</button>
          <button ngbDropdownItem (click)="deleteTemplate()"><i class="fal fa-trash"></i>{{ 'button.exclude' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="template__wrapper">
    <div class="template__wrapper-paper">
      <div id="tinymce"></div>
    </div>
  </div>
</section> -->
