<input class="d-none" /><!--  Macete para nao dar foco nos botoes -->
<div class="modal-body" [class.restoration]="isRestoration">
  <p class="centermodal__title" [ngClass]="{ 'centermodal__title-danger': !isRestoration }">{{ 'shared.attention' | translate }}</p>
  <p class="centermodal__description">{{ text }}</p>
  <img src="/assets/alerts/warning.svg" class="centermodal__img" />
  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <div class="centermodal__message">
      <div class="font-weight-bolder mb-2">
        {{ 'shared.' + (isRestoration ? 'whatHappensWhenRestoring' : 'whatHappensWhenDeletingDocument') | translate }}
      </div>
      <div class="explanation-list" *ngIf="!isRestoration">
        <div [innerHTML]="'shared.documentWillBeSentToTrash' | translate"></div>
        <div [innerHTML]="'shared.signersWhoHaveAlreadySigned' | translate"></div>
        <div [innerHTML]="'shared.deletingDocumentDoesNotPreventSigning' | translate"></div>
        <div [innerHTML]="'shared.blockingAndDeletingDocumentPreventsSigningAndAccess' | translate"></div>
      </div>
      <div class="explanation-list" *ngIf="isRestoration">
        <div [innerHTML]="'shared.documentWillBeRemovedFromTrashAndRestored' | translate"></div>
        <div *ngIf="showExtraBtn" [innerHTML]="'shared.pendingSignersCanOnlyAccessAndSignIfUnblocked' | translate"></div>
      </div>
    </div>
    <button type="button" class="btn-L btn-filled mb-1" [ngClass]="{ 'btn-filled-danger': !isRestoration }" (click)="modal.close(AlertButtonType.confirmExtra)" *ngIf="showExtraBtn">
      {{ 'button.' + (isRestoration ? 'unlockAndRestore' : 'lockAndDelete') | translate }}
    </button>
    <button type="button" class="btn-L" [ngClass]="deleteBtnClass()" (click)="modal.close(AlertButtonType.confirm)">
      {{ 'button.' + (showExtraBtn ? (isRestoration ? 'justRestore' : 'justDelete') : isRestoration ? 'restore' : 'exclude') | translate }}
    </button>
  </div>
  <div class="centermodal__button-group">
    <button type="button" class="btn-L btn-filled-cancel" (click)="modal.dismiss(AlertButtonType.close)">{{ 'button.cancel' | translate }}</button>
  </div>
</div>
