import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { finalize, switchMap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { environment } from '@env/environment';
import { AuthenticationMethod } from '@app/models';
import { AppService, AuthenticationService, ErrorHandlerService, NotyService, OauthService, PhoneService, UserService } from '@app/services';
import { CountdownTimerService } from '@app/services/countdowntimer.service';

enum LoginStepEnum {
  Login = 'l',
  Password = 'p',
  SmsCode = 'sms',
  WhatsappCode = 'whats',
  Register = 'r'
}

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['../auth.scss', './auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  isLoading = false;
  isSmsTimeout = false;
  loginStep = LoginStepEnum.Login;
  formattedTime = '';
  loginByMail = true;
  readonly isElectron = environment.electron;
  readonly AuthenticationMethod = AuthenticationMethod;
  readonly LoginStepEnum = LoginStepEnum;

  constructor(
    public route: ActivatedRoute,
    public whatsAppTimer: CountdownTimerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private appService: AppService,
    private authenticationService: AuthenticationService,
    private notyService: NotyService,
    private oauthService: OauthService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private phoneService: PhoneService
  ) {
    this.appService.loadStatusPageWidget();
  }

  ngOnDestroy() {}

  ngOnInit() {
    this.authenticationService.logout().subscribe();

    this.form = this.formBuilder.group({ email: this.route.snapshot.queryParams.email || '', phone: '', password: '', phoneCode: '', referral: this.route.snapshot.queryParams.r });

    if (this.form.get('email').value) {
      setTimeout(() => this.checkLoginStep(this.form.get('email').value), 500);
    }
  }

  checkLoginStep(emailOrPhoneInput: string) {
    let loginType: string | null = null;
    if (this.loginStep === LoginStepEnum.Login && this.isValidEmail(emailOrPhoneInput)) {
      loginType = 'email';
    } else if (!this.loginByMail && this.isValidInternationalPhone(emailOrPhoneInput)) {
      loginType = 'phone';
    }

    this.form.get(this.loginByMail ? 'email' : 'phone').markAsTouched();

    if (!loginType || (!this.loginByMail && !this.isValidInternationalPhone(emailOrPhoneInput)) || (this.loginByMail && !this.isValidEmail(emailOrPhoneInput))) {
      return;
    }

    this.isLoading = true;
    this.userService
      .userExists({ [loginType]: emailOrPhoneInput })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(userExists => {
        if (this.loginByMail && this.isValidEmail(emailOrPhoneInput)) {
          this.loginStep = userExists ? LoginStepEnum.Password : LoginStepEnum.Register;
          if (this.loginStep === LoginStepEnum.Password) {
            setTimeout(() => (document.querySelector('input[name=password]') as HTMLInputElement).focus());
          }
        } else if (userExists) {
          this.loginStep = userExists ? LoginStepEnum.SmsCode : LoginStepEnum.Register;
          this.sendPhoneCode(LoginStepEnum.SmsCode, emailOrPhoneInput, true);
          this.timeOutForWhatsappVerification();
        } else if (!userExists && loginType === 'phone') {
          this.form.get('phone')?.setErrors({ userExistsError: true });
        }
      });
  }

  login(method: AuthenticationMethod = AuthenticationMethod.Email, additionalInfo?: any) {
    this.isLoading = true;
    if ([AuthenticationMethod.Email, AuthenticationMethod.Sms, AuthenticationMethod.Whatsapp].includes(method)) {
      this.form.markAllAsTouched();
    }

    this.oauthService
      .login(method, additionalInfo)
      .pipe(
        switchMap(oauth =>
          this.authenticationService.login(
            oauth
              ? { code: oauth.accessToken, phone_number: oauth.phoneNumber, referral: this.form.get('referral').value }
              : { email: this.form.get('email').value, password: this.form.get('password').value, referral: this.form.get('referral').value },
            method
          )
        ),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true, queryParams: this.route.snapshot.queryParams }),
        error => {
          if (error?.status === 424) {
            this.router.navigate(['/confirmacao'], { queryParams: { email: this.form.get('email').value } });
          } else if (error?.status === 422 && [AuthenticationMethod.Sms, AuthenticationMethod.Whatsapp].includes(method)) {
            // Gambiarra pra transformar erro { message: 'token_invalid' } do back em algo que o front consiga usar pra mostrar erro no campo de código
            this.form.get('phoneCode').setErrors({ server: this.translateService.instant('error_phone_code_invalid') });
          } else {
            this.errorHandlerService.handleValidation(this.form, error);
          }
        }
      );
  }

  sendPhoneCode(step: LoginStepEnum, phone: string, notify: boolean = false) {
    this.isLoading = true;
    this.isSmsTimeout = true;
    (step === LoginStepEnum.WhatsappCode ? this.phoneService.sendWhatsappVerification(phone) : this.phoneService.sendSmsVerification(phone)).pipe(finalize(() => (this.isLoading = false))).subscribe(
      () => {
        this.loginStep = step;
        setTimeout(() => (document.querySelector('input[name=phoneCode]') as HTMLInputElement).focus());
        setTimeout(() => (this.isSmsTimeout = false), 20000);
        if (notify) {
          this.notyService.success(this.translateService.instant('notyService.confirmationCodeResentSuccess'));
        }
      },
      error => {
        this.isSmsTimeout = false;
        this.errorHandlerService.handle(error);
      }
    );
  }

  submit() {
    if (this.loginStep === LoginStepEnum.Login) {
      const inputValue = this.loginByMail ? this.form.get('email').value : this.form.get('phone').value;
      return this.checkLoginStep(inputValue);
    } else if (this.loginStep === LoginStepEnum.Register) {
      return this.router.navigate(['/cadastro'], {
        queryParams: {
          ...(this.route.snapshot.queryParams || {}),
          ...(this.isValidEmail(this.form.get('email').value) ? { email: this.form.get('email').value } : {})
        }
      });
    } else if ([LoginStepEnum.Password, LoginStepEnum.SmsCode, LoginStepEnum.WhatsappCode].includes(this.loginStep)) {
      const method = {
        [LoginStepEnum.Password]: AuthenticationMethod.Email,
        [LoginStepEnum.SmsCode]: AuthenticationMethod.Sms,
        [LoginStepEnum.WhatsappCode]: AuthenticationMethod.Whatsapp
      }[this.loginStep];
      const additionalInfo = this.isValidEmail(this.form.get('email').value) ? null : { phoneNumber: this.form.get('phone').value, phoneCode: this.form.get('phoneCode').value };
      return this.login(method, additionalInfo);
    }
  }

  isValidEmail(str: string) {
    return !!(str || '').match(/@/);
  }

  isValidInternationalPhone(str: string): boolean {
    const phonePattern = /^\+?[1-9]\d{1,14}$/;
    return phonePattern.test(str);
  }

  timeOutForWhatsappVerification() {
    if (this.whatsAppTimer.isCountdownInProgress()) {
      this.whatsAppTimer.stopCountdown();
    }
    this.whatsAppTimer
      .startCountdownForWhatsApp()
      .pipe(untilDestroyed(this))
      .subscribe(time => {
        this.formattedTime = time;
      });
  }
}
