import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';

import { StorageService } from './storage.service';
import { JwtPayload, jwtDecode } from 'jwt-decode';

export interface Credentials {
  accessToken: string;
  tokenType: string;
  expiresAt: number;
  firebaseToken: string;
}

export interface AccessTokenJwtPayload extends JwtPayload {
  email?: string;
  enterprise_id?: number;
  organization_id?: number;
}

const credentialsKey = 'painel-autentique-credentials';

/**
 * Provides storage for authentication credentials.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService implements OnDestroy {
  private _credentials: Credentials | null = null;
  private readonly credentialsChange: BehaviorSubject<Credentials>;

  constructor(private apollo: Apollo, private storageService: StorageService) {
    this._credentials = this.storageService.getJSON(credentialsKey);
    this.credentialsChange = new BehaviorSubject<Credentials>(this._credentials);
  }

  ngOnDestroy() {
    if (this.credentialsChange && !this.credentialsChange.closed) {
      this.credentialsChange.complete();
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials && new Date() < new Date(this.credentials.expiresAt);
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Gets the user credentials' header.
   * @return The user credentials' header or null if the user is not authenticated.
   */
  get authorizationHeader() {
    return this._credentials ? { Authorization: `${this._credentials.tokenType} ${this._credentials.accessToken}` } : null;
  }

  get decodedAccessToken() {
    return this._credentials?.accessToken ? this.decodeJwt(this._credentials.accessToken) : null;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember: boolean = true) {
    this._credentials = credentials || null;

    if (credentials) {
      this.storageService.setJSON(credentialsKey, credentials, !remember);
    } else {
      this.storageService.remove(credentialsKey);
    }

    if (this.credentialsChange && !this.credentialsChange.closed) {
      this.credentialsChange.next(credentials);
    }
  }

  /**
   * Clears the user credentials.
   */
  clearCredentials() {
    this.setCredentials();
    this.storageService.remove('painel-autentique-firestore-credentials');
    setTimeout(() =>
      this.apollo
        .getClient()
        .cache.reset()
        .then()
    );
  }

  onChangeCredentials() {
    return this.credentialsChange.asObservable();
  }

  decodeJwt(value: any) {
    return jwtDecode(value) as AccessTokenJwtPayload;
  }
}
