import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { filter, finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { ApiToken, User } from '@app/models';
import { ApiTokenService, AppService, ErrorHandlerService, NotyService, UserService, WhitelabelService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-api',
  templateUrl: './configurations-api.component.html',
  styleUrls: ['../configurations.scss', './configurations-api.component.scss']
})
export class ConfigurationsApiComponent implements OnInit, OnDestroy {
  currentUser: User;
  apiTokens: ApiToken[] = [];
  isLoading = false;
  isEditingApiOptions = false;

  constructor(
    public appService: AppService,
    public whitelabelService: WhitelabelService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private apiTokenService: ApiTokenService,
    private userService: UserService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.apiTokenService.all().subscribe(tokens => (this.apiTokens = tokens));

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}

  createApiToken() {
    this.isLoading = true;

    this.apiTokenService
      .create()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        tokens => (this.apiTokens = tokens.concat(this.apiTokens)),
        error => this.errorHandlerService.handle(error)
      );
  }

  deleteApiToken(apiAccessToken: string) {
    this.isLoading = true;

    this.apiTokenService
      .delete({ accessToken: apiAccessToken })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () =>
          this.apiTokens.splice(
            this.apiTokens.findIndex(token => token.access_token === apiAccessToken),
            1
          ),
        error => this.errorHandlerService.handle(error)
      );
  }
  copyApiLink(): void {
    this.notyService.success(this.translateService.instant('notyService.apiLinkCopied'), 2000);
  }
}
