import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApolloQueryResult, FetchPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { get } from 'lodash';

import { CredentialsService, AppApolloService, throwOnGraphqlError, I18nService } from '@app/core';
import { EmailTemplates, SignatureFormat, User, Locale, ExcludePlans } from '@app/models';
import { NotyService } from '@app/services/noty.service';
import { PhoneService } from '@app/services/phone.service';
import { UserExistGQL } from 'src/generated/graphql.public';
import {
  CurrentUserGQL,
  CurrentUserQuery,
  CurrentUserOrganizationGQL,
  CurrentUserCreditsGQL,
  Contact,
  ContactsGQL,
  ContactsQueryVariables,
  UpdateCurrentUserGQL,
  UpdateCurrentUserMutationVariables,
  UpdateCurrentUserPasswordGQL,
  UpdateCurrentUserPasswordMutationVariables,
  SetCurrentUserMissingInfoGQL,
  SetCurrentUserMissingInfoMutationVariables,
  UpdateCurrentUserSignatureGQL,
  UpdateCurrentUserSignatureMutationVariables,
  UpdateCurrentUserInitialsGQL,
  UpdateCurrentUserInitialsMutationVariables,
  UpdateCurrentUserSignatureEmailGQL,
  UpdateCurrentUserSignatureEmailMutationVariables,
  NotificationsInfoGQL,
  UpdateNotificationsInfoGQL,
  UpdateNotificationsInfoMutationVariables,
  UpdateApiOptionsGQL,
  UpdateApiOptionsMutationVariables,
  UpdateCurrentUserLocaleMutationVariables,
  UpdateCurrentUserLocaleGQL
} from 'src/generated/graphql.default';

export type CustomContact = Contact & { _isSelected?: boolean };
export interface SimpleUserWithOrganization {
  company?: string;
  cnpj?: string;
  member?: {
    group?: {
      company?: string;
      cnpj?: string;
      styles?: { overwrite_name: boolean };
    };
  };
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private userLocale: Locale;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private appApolloService: AppApolloService,
    private credentialsService: CredentialsService,
    private notyService: NotyService,
    private i18nService: I18nService,
    private userExistGQL: UserExistGQL,
    private currentUserGQL: CurrentUserGQL,
    private currentUserOrganizationGQL: CurrentUserOrganizationGQL,
    private currentUserCreditsGQL: CurrentUserCreditsGQL,
    private contactsGQL: ContactsGQL,
    private updateCurrentUserGQL: UpdateCurrentUserGQL,
    private updateCurrentUserPasswordGQL: UpdateCurrentUserPasswordGQL,
    private updateCurrentUserSignatureGQL: UpdateCurrentUserSignatureGQL,
    private updateCurrentUserInitialsGQL: UpdateCurrentUserInitialsGQL,
    private updateCurrentUserSignatureEmailGQL: UpdateCurrentUserSignatureEmailGQL,
    private setCurrentUserMissingInfoGQL: SetCurrentUserMissingInfoGQL,
    private notificationsInfoGQL: NotificationsInfoGQL,
    private updateNotificationsInfoGQL: UpdateNotificationsInfoGQL,
    private updateApiOptionsGQL: UpdateApiOptionsGQL,
    private updateCurrentUserLocaleGQL: UpdateCurrentUserLocaleGQL
  ) {}

  getCurrentUser(options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'cache-first' }): Observable<User> {
    return this.currentUserGQL.fetch(null, options).pipe(
      throwOnGraphqlError(),
      map(response => this.parseUser(response)),
      tap(user => this.setUserLocale(user))
    );
  }

  watchCurrentUser(options: { fetchPolicy: WatchQueryFetchPolicy } = { fetchPolicy: 'cache-first' }): Observable<User> {
    return this.credentialsService.onChangeCredentials().pipe(
      switchMap(credentials => {
        if (credentials) {
          return this.currentUserGQL.watch(null, options).valueChanges.pipe(
            throwOnGraphqlError(),
            map(response => this.parseUser(response)),
            tap(user => this.setUserLocale(user))
          );
        } else {
          return of(null);
        }
      })
    );
  }

  currentUserOrganization() {
    return this.currentUserOrganizationGQL.fetch(null).pipe(map(response => response.data.me.organization));
  }

  currentUserCredits() {
    return this.currentUserCreditsGQL.fetch(null).pipe(map(response => response.data.me.subscription));
  }

  userExists(attributes: { email?: string; phone?: string } = {}) {
    if (attributes.phone) {
      attributes.phone = (attributes.phone || '').trim();
    }
    if (attributes.email) {
      attributes.email = (attributes.email || '').trim();
    }
    return this.userExistGQL.fetch(attributes, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.userExist)
    );
  }

  notificationsInfo() {
    return this.notificationsInfoGQL.fetch(null).pipe(map(response => response.data.notifications));
  }

  contacts(variables: ContactsQueryVariables, options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'cache-first' }) {
    return this.contactsGQL.fetch(variables, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.contacts?.data)
    );
  }

  updateCurrentUser(variables: UpdateCurrentUserMutationVariables) {
    variables.user.name = (variables.user.name || '').trim();
    if (variables.user.organization) {
      variables.user.organization.name = (variables.user.organization.name || '').trim() || null;
      // variables.user.organization.cnpj = (variables.user.organization.cnpj || '').trim() || null;
    }
    if (variables.user.email) {
      variables.user.email = (variables.user.email || '').trim();
    }

    return this.updateCurrentUserGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUser)
    );
  }

  updateCurrentUserPassword(variables: UpdateCurrentUserPasswordMutationVariables) {
    return this.updateCurrentUserPasswordGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.changeUserPassword)
    );
  }

  updateCurrentUserSignature(variables: UpdateCurrentUserSignatureMutationVariables) {
    return this.updateCurrentUserSignatureGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUserSignature),
      tap(user => this.appApolloService.forceUpdateCurrentUserCache({ me: user }))
    );
  }

  updateCurrentUserInitials(variables: UpdateCurrentUserInitialsMutationVariables) {
    return this.updateCurrentUserInitialsGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUserInitials),
      tap(user => this.appApolloService.forceUpdateCurrentUserCache({ me: user }))
    );
  }

  updateCurrentUserSignatureEmail(variables: UpdateCurrentUserSignatureEmailMutationVariables) {
    return this.updateCurrentUserSignatureEmailGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUserSignatureEmail),
      tap(user => this.appApolloService.forceUpdateCurrentUserCache({ me: user }))
    );
  }

  updateCurrentUserLocale(variables: UpdateCurrentUserLocaleMutationVariables) {
    return this.updateCurrentUserLocaleGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUserLocale)
    );
  }

  updateNotificationsInfo(variables: UpdateNotificationsInfoMutationVariables) {
    return this.updateNotificationsInfoGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateNotifications)
    );
  }

  updateApiOptions(variables: UpdateApiOptionsMutationVariables) {
    return this.updateApiOptionsGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      switchMap(() => this.currentUserOrganization())
    );
  }

  setCurrentUserMissingInfo(variables: SetCurrentUserMissingInfoMutationVariables) {
    return this.setCurrentUserMissingInfoGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateUserDetails)
    );
  }

  isSubscriptionFree(user: User) {
    return user && ((user.subscription.name || '').toLowerCase() === 'free' || this.hasSubscriptionEnded(user));
  }

  isSubscriptionPaid(user: User) {
    return user && !['empresarial', 'free'].includes((user.subscription.name || '').toLowerCase()) && !this.hasSubscriptionEnded(user);
  }

  getCompany(user: SimpleUserWithOrganization) {
    return user.member && user.member.group && user.member.group.styles && user.member.group.styles.overwrite_name ? user.member.group.company || user.company : user.company;
  }

  getCompanyCnpj(user: SimpleUserWithOrganization) {
    return user.member && user.member.group && user.member.group.styles && user.member.group.styles.overwrite_name ? user.member.group.cnpj || user.cnpj : user.cnpj;
  }

  getSubscriptionName(user: User) {
    return this.isSubscriptionFree(user) ? 'Grátis' : user.subscription.name;
  }

  permissionCheck(condition: boolean) {
    if (!condition) {
      this.router.navigate(['/'], { replaceUrl: true });
      return this.notyService.error(this.translateService.instant('error_without_permission_page'));
    }
  }

  setUserLocale(user: User) {
    const userLocale = user?.locale;
    if (userLocale) {
      this.userLocale = user.locale;
      if (userLocale.language) this.translateService.use(userLocale.language);
    }
  }

  getUserLocale() {
    return this.userLocale;
  }

  private hasSubscriptionEnded(user: User) {
    return user && user.subscription.expires_at && new Date(user.subscription.expires_at) < new Date();
  }

  private parseUser(response: ApolloQueryResult<CurrentUserQuery>) {
    const currentUser = {
      ...response.data.me,
      newHash: response.data.newHash,
      currentPermissions: get(response.data.me.member.group, 'permissions.overwrite_permissions') ? response.data.me.member.group.permissions : response.data.me.member.permissions
    } as User;
    currentUser.company = this.getCompany(currentUser);
    currentUser.cnpj = this.getCompanyCnpj(currentUser);
    currentUser.settings = currentUser.settings || {};
    currentUser.settings.format = currentUser.settings.format || SignatureFormat.Handwriting;
    currentUser.settings.initials_format = currentUser.settings.initials_format || SignatureFormat.Handwriting;
    currentUser.settings.email = currentUser.settings.email || {};
    currentUser.settings.email.template = currentUser.settings.email.template || EmailTemplates.Default;
    currentUser.settings.email.title = currentUser.settings.email.title || '{{nome}} enviou um documento para você {{funcao}}';
    currentUser.settings.email.subject = currentUser.settings.email.subject || 'Enviou um documento para você {{funcao}}';
    currentUser.settings.email.sender = currentUser.settings.email.sender || currentUser.company || currentUser.name;
    currentUser.settings.email.text = currentUser.settings.email.text || 'Por favor acesse e assine eletronicamente o documento clicando no botão acima.';
    currentUser.settings.email.image = currentUser.settings.email.image || 'https://storage.googleapis.com/user-profile-assets/autentique.png';
    currentUser.settings.email.colors = (currentUser.settings.email.colors || []).length > 0 ? currentUser.settings.email.colors : ['#3379F2', '#3379F2'];
    return currentUser;
  }
}
