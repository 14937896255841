import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Logger } from '@app/core/logger.service';
import { UserService } from '@app/services/user.service';

const log = new Logger('NoChildOrganizationAccessGuard');

@Injectable({
  providedIn: 'root'
})
export class NoChildOrganizationAccessGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> {
    return this.userService.getCurrentUser().pipe(
      map(user => {
        if (!user?.organization?.parent) {
          return true;
        }

        log.debug('Child organization cannot access this route, redirecting...');
        this.router.navigate(['/404'], { replaceUrl: true });
        return false;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
