<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12">
      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 class="text-center">Alguma coisa inesperada aconteceu</h3>

          <div class="text-center">
            <img src="/assets/alerts/confirmation-2.svg" class="w-100" alt="" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-left text-color">
          <div [innerHTML]="'httpError.loggedOutMessage' | translate"></div>
        </div>
      </div>
    </div>
  </div>
</div>
