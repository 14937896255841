import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertButtonType } from '@app/models';

export type ModalResult = AlertButtonType;
export interface ModalPublicProperties {
  text: string;
  showExtraBtn?: boolean;
  isRestoration?: boolean;
}

@Component({
  selector: 'app-document-delete-alert-modal',
  templateUrl: './document-delete-alert-modal.component.html',
  styleUrls: ['./document-delete-alert-modal.component.scss']
})
export class DocumentDeleteAlertModalComponent implements ModalPublicProperties {
  @Input() text: string;
  @Input() showExtraBtn = false;
  @Input() isRestoration = false;
  readonly AlertButtonType = AlertButtonType;

  constructor(public modal: NgbActiveModal) {}

  deleteBtnClass() {
    if (this.isRestoration) {
      return 'btn-filled';
    }

    if (this.showExtraBtn) {
      return 'btn-text-danger';
    }

    return 'btn-filled-danger';
  }
}
