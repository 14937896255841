import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { uniqBy, groupBy } from 'lodash';

import { EmailTemplateTypeEnum, OrganizationGroup, EmailTemplateItem, SlimEmailTemplate } from '@app/models';
import { EmailTemplateService, ErrorHandlerService, OrganizationService } from '@app/services';

export type ModalResult = OrganizationGroup;
export interface ModalPublicProperties {
  group: OrganizationGroup;
}

@Component({
  selector: 'app-create-organization-group-modal',
  templateUrl: './edit-organization-group-appearance-modal.component.html',
  styleUrls: ['./edit-organization-group-appearance-modal.component.scss']
})
export class EditOrganizationGroupAppearanceModalComponent implements ModalPublicProperties, AfterContentInit, OnInit {
  form!: FormGroup;
  group: OrganizationGroup;
  emailTemplates: SlimEmailTemplate[] = [];
  isLoading = false;

  isOverwriteName: boolean;
  isOverWritingEmail: boolean;

  emailTemplateItems: EmailTemplateItem[] = [
    { type: EmailTemplateTypeEnum.Solicitation, label: 'signatureRequest', ngModel: null, options: [] },
    { type: EmailTemplateTypeEnum.SignatureCompleted, label: 'signatureSuccess', ngModel: null, options: [] },
    { type: EmailTemplateTypeEnum.Completed, label: 'documentCompletion', ngModel: null, options: [] }
  ];

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private emailTemplateService: EmailTemplateService,
    private organizationService: OrganizationService
  ) {
    this.form = this.formBuilder.group({
      styles: this.formBuilder.group({
        overwrite_email: false,
        overwrite_name: false,
        email_templates_ids: this.formBuilder.array(this.emailTemplateItems.map(item => item.ngModel)),
        company: '',
        cnpj: ''
      })
    });
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    const emailTemplateIds = Array.from({ length: this.emailTemplateItems.length }, (_, i) => this.group.email_templates[i]?.id || null);

    this.form.setValue({
      styles: {
        email_templates_ids: emailTemplateIds,
        overwrite_email: this.group.styles.overwrite_email,
        overwrite_name: this.group.styles.overwrite_name,
        company: this.group.company,
        cnpj: this.group.cnpj
      }
    });

    this.onChangeOverwriteName(this.form.get('styles.overwrite_name').value);
    this.onChangeOverwriteEmail(this.form.get('styles.overwrite_email').value);

    this.requestSlimTemplates();
  }

  requestSlimTemplates() {
    this.isLoading = true;

    forkJoin(this.emailTemplateItems.map(item => this.emailTemplateService.slimEmailTemplates({ page: 1, limit: 60, type: item.type })))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(pages => {
        // this operator makes sure the current selected group.email_template always shows on the app-select list + 60 more from query, with uniq ID`s
        this.emailTemplates = uniqBy(this.emailTemplates.concat(...pages.map(page => page.data)), 'id');
        this.fillTemplateOptions(this.emailTemplates);

        this.group.email_templates.forEach(emailTemplate => {
          const templateItem = this.emailTemplateItems.find(item => item.type === emailTemplate.type);
          if (templateItem) {
            templateItem.ngModel = emailTemplate.id;
          }
        });
      });
  }

  onChangeOverwriteEmail(value: boolean) {
    this.isOverWritingEmail = value;
  }

  onChangeOverwriteName(value: boolean) {
    this.isOverwriteName = !!value;
  }

  onChangeSelectedTemplate() {
    this.form.patchValue({
      styles: { email_templates_ids: this.emailTemplateItems.map(item => item.ngModel) }
    });
  }

  update() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    const emailTemplatesArray = this.form.get('styles.email_templates_ids') as FormArray; // removes any null from id`s array
    for (let i = emailTemplatesArray.length - 1; i >= 0; i--) {
      if (emailTemplatesArray.at(i).value === null) {
        emailTemplatesArray.removeAt(i);
      }
    }

    this.organizationService
      .updateGroupStyles({ ...this.form.getRawValue(), id: this.group.id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }

  fillTemplateOptions(emailTemplates: SlimEmailTemplate[]) {
    const dividedEmailTemplates = groupBy(emailTemplates, 'type');

    this.emailTemplateItems.forEach(item => {
      item.options = [];
      item.options.push({ key: null, value: this.translateService.instant('settings.defaultTemplate'), height: 46, fontSize: 16 });
      item.options.push(...(dividedEmailTemplates[item.type] || []).map(emailTemplate => ({ key: emailTemplate.id, value: emailTemplate.name, height: 46, fontSize: 16 })));
    });
  }
}
