import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { PhoneService } from '@app/services/phone.service';

/**
 * Formats phone number
 */
@Pipe({ name: 'phone$' })
export class PhonePipe implements PipeTransform {
  constructor() {}

  transform(phone: string): Observable<string> {
    return PhoneService.getFullPhoneNumberWithMask(phone);
  }
}
