import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import { of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import { Whitelabel, ListFormat } from '@app/models';
import { AppService } from './app.service';
import {
  ActivateWhitelabelDomainGQL,
  ActivateWhitelabelSendgridGQL,
  UpdateWhitelabelAppearanceGQL,
  UpdateWhitelabelAppearanceMutationVariables,
  UpdateWhitelabelDomainGQL,
  UpdateWhitelabelDomainMutationVariables,
  UpdateWhitelabelSendgridGQL,
  UpdateWhitelabelSendgridMutationVariables,
  WhitelabelAppearanceInput,
  WhitelabelDomainVerifiedGQL,
  WhitelabelDomainVerifiedQueryVariables,
  WhitelabelGQL,
  WhitelabelSendgridDnsGQL,
  WhitelabelSendgridDnsQueryVariables,
  WhitelabelSendgridValidateGQL,
  WhitelabelSendgridValidateMutationVariables
} from 'src/generated/graphql.default';
import { WhitelabelByDomainGQL, WhitelabelByDocumentGQL, WhitelabelByDocumentQueryVariables } from 'src/generated/graphql.public';

export interface WhitelabelParams {
  loginRequired: number;

  tab: number;
  sidebar: number;

  configPersonal: number;
  configAppearance: number;
  configNotifications: number;
  configGeneral: number;
  configPlans: number;
  configCredits: number;
  configEmails: number;
  configMembers: number;
  configGroups: number;
  configWebhooks: number;
  configCustomization: number;
  configApi: number;
  configReferal: number;

  folderBreadCrumbs: number;
  documentationLink: number;

  mobileNavigation: number;
  menuBackButton: number;
  mainPath: string;
  listType: ListFormat;
  [key: string]: any;
}

interface Params {
  [key: string]: boolean | string | ListFormat;
}

@Injectable({ providedIn: 'root' })
export class WhitelabelService {
  logo = '/assets/Autentique.svg';
  pdfLogo = '/assets/autentique-logo.svg';
  pdfLogoMini = '/assets/autentique-logo.svg';
  mainColor = '#3379F2';

  params: Params = {
    loginRequired: true,

    tab: true,
    sidebar: true,

    configPersonal: true,
    configAppearance: true,
    configNotifications: true,
    configGeneral: true,
    configPlans: true,
    configCredits: true,
    configEmails: true,
    configMembers: true,
    configGroups: true,
    configWebhooks: true,
    configCustomization: true,
    configApi: true,
    configReferal: true,

    configOrganization: true,
    configDeveloper: true,
    configOther: true,

    folderBreadCrumbs: true,
    documentationLink: true,

    menuBackButton: true,
    mobileNavigation: true,

    mainPath: '',
    listType: ListFormat.Box
  };

  appearance: WhitelabelAppearanceInput = {};
  isEnterprise = false;

  isApplicationWhitelabelLoaded = false;
  private _whitelabel: Whitelabel;

  constructor(
    private whitelabelGQL: WhitelabelGQL,
    private whitelabelByDomainGQL: WhitelabelByDomainGQL,
    private whitelabelByDocumentGQL: WhitelabelByDocumentGQL,
    private whitelabelSendgridDnsGQL: WhitelabelSendgridDnsGQL,
    private whitelabelDomainVerifiedGQL: WhitelabelDomainVerifiedGQL,
    private updateWhitelabelAppearanceGQL: UpdateWhitelabelAppearanceGQL,
    private updateWhitelabelSendgridGQL: UpdateWhitelabelSendgridGQL,
    private updateWhitelabelDomainGQL: UpdateWhitelabelDomainGQL,
    private whitelabelSendgridValidateGQL: WhitelabelSendgridValidateGQL,
    private activateWhitelabelSendgridGQL: ActivateWhitelabelSendgridGQL,
    private activateWhitelabelDomainGQL: ActivateWhitelabelDomainGQL,
    private appService: AppService
  ) {}

  get whitelabel(): Whitelabel {
    return this._whitelabel;
  }

  // Used only on Configurations
  loadCurrentWhitelabel() {
    return of(null).pipe(
      switchMap(() => this.whitelabelGQL.fetch(null, { fetchPolicy: 'no-cache' })),
      throwOnGraphqlError(),
      map(response => response.data.whitelabel)
    );
  }

  // Used on all Application
  loadWhitelabelByDomain(options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'network-only' }) {
    return of(null).pipe(
      switchMap(() => this.whitelabelByDomainGQL.fetch({ domain: location.hostname }, { fetchPolicy: options.fetchPolicy })),
      throwOnGraphqlError(),
      map(response => response.data.whitelabelByDomain),
      tap(whitelabel => {
        this._whitelabel = this.buildWhitelabel(whitelabel);
        this.appService.isDarkmode = whitelabel.is_darkmode;
        this.setFavicon(whitelabel.pdf_logo_mini || this.pdfLogoMini);
      })
    );
  }

  // Used on Document Whitelabel
  loadWhitelabelByDocument(variables: WhitelabelByDocumentQueryVariables, options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'network-only' }) {
    return of(null).pipe(
      switchMap(() => this.whitelabelByDocumentGQL.fetch(variables, options)),
      throwOnGraphqlError(),
      map(response => response.data.whitelabelByDocument)
    );
  }

  buildWhitelabel(whitelabel?: Whitelabel): Whitelabel {
    return {
      domain: whitelabel?.domain || null,
      sendgrid_domain: whitelabel?.sendgrid_domain || null,
      sendgrid_email_user: whitelabel?.sendgrid_email_user || null,
      is_darkmode: typeof (whitelabel || {}).is_darkmode === 'boolean' ? whitelabel.is_darkmode : false,
      main_color: whitelabel?.main_color || this.mainColor,
      logo: whitelabel?.logo || this.logo,
      pdf_logo: whitelabel?.pdf_logo || this.pdfLogo,
      pdf_logo_mini: whitelabel?.pdf_logo_mini || this.pdfLogoMini
    };
  }

  resetWhitelabelInfo() {
    this._whitelabel = this.buildWhitelabel();
  }

  setFavicon(url: string = '/assets/icons/android-icon-192x192.png') {
    // Removes all favicons
    document.querySelectorAll('.favicon').forEach(link => link.remove());

    // Adds Whitelabel to favicon and title
    document.title = 'Assinatura de documento';
    const newLink = document.createElement('link');

    newLink.rel = 'icon';
    newLink.type = 'image/png';
    newLink.href = url || '/assets/icons/android-icon-192x192.png';
    newLink.setAttribute('class', 'favicon');

    document.head.appendChild(newLink);
  }

  whitelabelDomainVerified(domain: WhitelabelDomainVerifiedQueryVariables) {
    return this.whitelabelDomainVerifiedGQL.fetch(domain).pipe(
      throwOnGraphqlError(),
      map(response => response.data.whitelabelDomainVerified)
    );
  }

  sendgridDns(sendgridDomain: WhitelabelSendgridDnsQueryVariables) {
    return this.whitelabelSendgridDnsGQL.fetch(sendgridDomain).pipe(
      throwOnGraphqlError(),
      map(response => response.data.whitelabelSendgridDns)
    );
  }

  updateWhitelabelAppearance(whitelabel: UpdateWhitelabelAppearanceMutationVariables) {
    return this.updateWhitelabelAppearanceGQL.mutate(whitelabel).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateWhitelabelAppearance)
    );
  }

  updateWhitelabelSendgrid(whitelabel: UpdateWhitelabelSendgridMutationVariables) {
    return this.updateWhitelabelSendgridGQL.mutate(whitelabel).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateWhitelabelSendgrid)
    );
  }

  updateWhitelabelDomain(domain: UpdateWhitelabelDomainMutationVariables) {
    return this.updateWhitelabelDomainGQL.mutate(domain).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateWhitelabelDomain)
    );
  }

  whitelabelSendgridValidate(sendgrid_domain: WhitelabelSendgridValidateMutationVariables) {
    return this.whitelabelSendgridValidateGQL.mutate(sendgrid_domain).pipe(
      throwOnGraphqlError(),
      map(response => response.data.whitelabelSendgridValidate)
    );
  }

  activateWhitelabelSendgrid() {
    return this.activateWhitelabelSendgridGQL.mutate().pipe(throwOnGraphqlError());
  }

  activateWhitelabelDomain() {
    return this.activateWhitelabelDomainGQL.mutate().pipe(throwOnGraphqlError());
  }

  activateMenuFromParam(activeValue: number, key: string) {
    // Controls which menu will be active based on value (1 or 0)
    if (this.params.hasOwnProperty(key)) {
      this.params[key] = String(activeValue) === '1';
      this.isEnterprise = true;
    }
    this.checkMenuTitles();
  }

  receiveParams(params: WhitelabelParams) {
    for (const key in params) {
      if (key === 'mainPath') {
        this.params[key] = params[key];
      } else if (key === 'listType') {
        this.changeFormatList(params[key]);
      } else if (params.hasOwnProperty(key)) {
        // Receives {key: value} and calls activateMenu for each key

        const value = params[key];
        this.activateMenuFromParam(value, key);
      }
    }
  }

  checkMenuTitles() {
    // Controls if the menus will show or not based on params

    const p = this.params;
    if (!p.configGeneral && !p.configPlans && !p.configEmails && !p.configMembers && !p.configGroups && !p.configWebhooks) {
      p.configOrganization = false;
    }
    if (!p.configApi) {
      p.configDeveloper = false;
    }
    if (!p.configReferal) {
      p.configOther = false;
    }
  }

  changeFormatList(format: string) {
    this.appService.listFormat = format as ListFormat;
  }

  isWhitelabel() {
    if (!this.isEnterprise && this.isApplicationWhitelabelLoaded) {
      const conditions: boolean[] = [
        !!this.whitelabel.domain,
        this.logo !== this.whitelabel.logo,
        this.pdfLogo !== this.whitelabel.pdf_logo,
        this.pdfLogoMini !== this.whitelabel.pdf_logo_mini,
        this.mainColor !== this.whitelabel.main_color
      ];

      return conditions.includes(true);
    }

    return this.isEnterprise;
  }
}
