import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { untilDestroyed } from '@app/core';
import { CreditPackType, Customer, Order, PaymentMethodsEnum, User, CreditLogType, Invoice } from '@app/models';
import { LoaderService, SetMissingUserInfoModalService } from '@app/shared';
import { AppService, ErrorHandlerService, NotyService, OrganizationService, PaymentService, UserService } from '@app/services';
import { EditPaymentInfoModalService } from '../configurations-plan/edit-payment-info-modal/edit-payment-info-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-configurations-credits',
  templateUrl: './configurations-credits.component.html',
  styleUrls: ['../configurations.scss', './configurations-credits.component.scss', '../../../shared/payment-warning/payment-warning.component.scss']
})
export class ConfigurationsCreditsComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentCustomer: Customer;
  currentOrder: Order; // pra pegar o cartao salvo se existir
  credits: CreditPackType[];
  invoice: Invoice;
  creditLogs: CreditLogType[];
  creditLogPageNumber: number;
  creditLogIsLastPage = false;

  isLoading = false;
  PaymentMethodsEnum = PaymentMethodsEnum;

  constructor(
    public appService: AppService,
    public translateService: TranslateService,
    public userService: UserService,
    public organizationService: OrganizationService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private paymentService: PaymentService,
    private editPaymentInfoModalService: EditPaymentInfoModalService,
    private setMissingUserInfoModalService: SetMissingUserInfoModalService
  ) {}

  ngOnInit() {
    const credits$ = this.paymentService.credits();
    const currentOrder$ = this.paymentService.order();

    this.userService
      .watchCurrentUser({ fetchPolicy: 'network-only' })
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        if (this.currentUser.currentPermissions.change_plan_oz) {
          this.isLoading = true;
          this.loaderService.show();

          const invoice$ = this.paymentService.organizationInvoices({ organizationUuid: this.currentUser.organization.uuid, limit: 1, page: 1 });
          this.loadCreditLogs();
          forkJoin([credits$, currentOrder$, invoice$])
            .pipe(
              finalize(() => {
                this.isLoading = false;
                this.loaderService.hide();
              })
            )
            .subscribe(([credits, order, invoice]) => {
              this.credits = credits;
              this.currentOrder = order;
              this.invoice = invoice.data[0];
            });

          setTimeout(() => this.paymentService.currentCustomer().subscribe(currentCustomer => (this.currentCustomer = currentCustomer)), 100);
        }
      });

    this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(() => {
      this.setMissingUserInfoModalService.openIfMissingUserInfo({ ignoreCpf: true, dismissAsError: true }).subscribe(
        () => {},
        () => this.router.navigate(['/'])
      );
    });
  }

  ngOnDestroy() {}

  loadCreditLogs(options: { page?: number } = {}) {
    const limit = 15;
    this.creditLogPageNumber = options.page || 1;
    this.paymentService.creditLogs({ limit, page: this.creditLogPageNumber }).subscribe(
      page => {
        this.creditLogs = (this.creditLogPageNumber === 1 ? [] : this.creditLogs || []).concat(page);
        this.creditLogIsLastPage = page.length < limit;
      },
      error => this.errorHandlerService.handle(error)
    );
  }

  buySelectedCredit(credit: CreditPackType) {
    this.editPaymentInfoModalService.open({ currentCustomer: this.currentCustomer, currentOrder: this.currentOrder, creditsPack: credit }).subscribe(data => {
      this.currentCustomer = data.currentCustomer;
      this.buyCredits(credit, data.payableWith, data.cardToken);
    });
  }

  buyCredits(credit: CreditPackType, paymentMethod?: PaymentMethodsEnum, cardToken?: string) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loaderService.show();

      this.paymentService
        .buyCredits({
          package: credit.name,
          ...(paymentMethod ? { payment_method: paymentMethod } : {}),
          ...(cardToken ? { card_token: cardToken } : {})
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.loaderService.hide();
          })
        )
        .subscribe(
          invoice => {
            this.invoice = invoice;
            this.notyService.success(this.translateService.instant('notyService.orderRegistered'));
          },
          error => {
            this.errorHandlerService.handle(error);
          }
        );
    }
  }

  isPendingCreditInvoice(invoice: Invoice) {
    const regex = /cr[eé]ditos?|credit/i;
    return invoice && invoice?.status === 'pending' && regex.test(invoice?.description);
  }
}
