<ng-container *ngIf="whitelabelService.isApplicationWhitelabelLoaded">
  <div class="auth__container" [class.whitelabeled]="whitelabelService.isWhitelabel()">
    <div class="container">
      <div class="autentique-logo-holder" *ngIf="whitelabelService.isApplicationWhitelabelLoaded">
        <img class="autentique-logo" [src]="whitelabelService.whitelabel.logo" alt="" />
      </div>
      <ng-content></ng-content>
      <div class="terms">
        <a href="https://www.autentique.com.br/termos-de-uso/" class="terms-item">{{ 'external.useTerm' | translate }}</a>
        <a href="https://www.autentique.com.br/politica-de-privacidade/" class="terms-item">{{ 'external.privacyPolicy' | translate }}</a>
      </div>
    </div>
  </div>
  <app-auth-brand-container [backToRegister]="backToRegister" [class.d-none]="whitelabelService.isWhitelabel()"></app-auth-brand-container>
</ng-container>
