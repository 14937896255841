import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EmailTemplatesContentInput, EmailTemplates, User, EmailTemplateTypeEnum } from '@app/models';
import { Nl2brPipe } from '@app/shared/pipes';
import { ParseEmailVariablesPipe } from './parse-email-variables.pipe';

@Component({
  selector: 'app-signature-email-preview',
  templateUrl: './signature-email-preview.component.html',
  styleUrls: ['./signature-email-preview.component.scss']
})
export class SignatureEmailPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() user: User;
  @Input() emailSettings: EmailTemplatesContentInput;
  @Input() emailType: EmailTemplateTypeEnum;
  @Input() selectedLang: string;
  iframeHeight = 500;
  @ViewChild('iframe', { static: false }) private iframe: ElementRef<HTMLIFrameElement>;
  private currentTemplate: string;
  private imageUrl: string;

  constructor(private httpClient: HttpClient, private parseEmailVariablesPipe: ParseEmailVariablesPipe, private nl2brPipe: Nl2brPipe) {}

  ngOnInit() {
    this.getCurrentTemplate();
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.imageUrl);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.user && changes.user.currentValue && changes.user.currentValue !== changes.user.previousValue) ||
      (changes.emailSettings && changes.emailSettings.currentValue && changes.emailSettings.currentValue !== changes.emailSettings.previousValue)
    ) {
      this.useTemplate(this.currentTemplate, changes);
    }
    if (changes.emailSettings?.currentValue || changes.emailType || changes.selectedLang) {
      if (changes.emailSettings?.currentValue?.template !== (changes.emailSettings.previousValue || {}).template || changes.emailType || changes.selectedLang) {
        this.getCurrentTemplate().subscribe(template => this.useTemplate(template, changes));
      }
    }
  }

  private getTemplateUrl(emailType: EmailTemplateTypeEnum) {
    const lang = ['pt-BR', 'ptBR'].includes(this.selectedLang) ? 'pt' : 'en';
    const name = {
      [EmailTemplateTypeEnum.Solicitation]: this.emailSettings.template || 'padrao',
      [EmailTemplateTypeEnum.SignatureCompleted]: 'completed',
      [EmailTemplateTypeEnum.Completed]: 'doc-completed'
    }[emailType || EmailTemplateTypeEnum.Solicitation];

    return `${window.origin}/assets/email-templates/${lang}/email-template-${name}.html`;
  }

  private getCurrentTemplate() {
    if (this.emailSettings.template || this.emailType === EmailTemplateTypeEnum.SignatureCompleted) {
      return this.httpClient
        .disableLoader()
        .cache({ expiresIn: 604800 })
        .get(this.getTemplateUrl(this.emailType), { responseType: 'text' })
        .pipe(
          tap(template => (this.currentTemplate = template)),
          tap(() => (this.iframeHeight = this.emailSettings.template === EmailTemplates.Default ? 900 : 500))
        );
    } else {
      return of(null);
    }
  }

  private useTemplate(template: string, changes: SimpleChanges) {
    if (template && this.user && this.user.settings && this.user.settings.email) {
      let parsedTemplate = template;
      parsedTemplate = parsedTemplate.replace(/\{\{TITLE\}\}/g, this.nl2brPipe.transform(this.parseEmailVariablesPipe.transform(this.emailSettings.title, this.user)));
      parsedTemplate = parsedTemplate.replace(/\{\{FOOTER\}\}/g, this.nl2brPipe.transform(this.parseEmailVariablesPipe.transform(this.emailSettings.footer, this.user)));
      parsedTemplate = parsedTemplate.replace(/\{\{TEXT\}\}/g, this.nl2brPipe.transform(this.parseEmailVariablesPipe.transform(this.emailSettings.text, this.user)));
      parsedTemplate = parsedTemplate.replace(/\{\{COLOR_0\}\}/g, (this.emailSettings.colors || [])[0] || '');
      parsedTemplate = parsedTemplate.replace(/\{\{COLOR_1\}\}/g, (this.emailSettings.colors || [])[1] || '');

      if (!this.emailSettings.image || typeof this.emailSettings.image === 'string') {
        this.imageUrl = this.emailSettings.image || '/assets/Autentique.svg';
      } else {
        if (changes?.emailSettings?.previousValue?.image !== changes?.emailSettings?.currentValue?.image) {
          URL.revokeObjectURL(this.imageUrl);
          this.imageUrl = URL.createObjectURL(this.emailSettings.image);
        }
      }
      parsedTemplate = parsedTemplate.replace(/\{\{IMAGE\}\}/g, this.imageUrl);

      setTimeout(() => {
        this.iframe.nativeElement.contentWindow.document.open();
        this.iframe.nativeElement.contentWindow.document.write(parsedTemplate);
        this.iframe.nativeElement.contentWindow.document.close();
      });
    }
  }
}
