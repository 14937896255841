<form class="modal-body" [formGroup]="form" (ngSubmit)="transfer()" novalidate>
  <p class="centermodal__title">{{ 'documents.' + (isRestoration ? 'restoreDocument' : 'transferDocument') | translate }}</p>
  <p class="centermodal__description" *ngIf="isRestoration">{{ 'documents.transferDocumentDescription' | translate }}</p>
  <p class="centermodal__description" *ngIf="!isRestoration">{{ 'documents.transferDocumentAction' | translate }}</p>

  <div class="centermodal__form">
    <div [appFormControlWrap]="form.get('organization')" *ngIf="organizations.length > 0">
      <app-select placeholder="{{ 'documents.organization' | translate }}" formControlName="organization" [options]="organizations" [required]="true"></app-select>
    </div>

    <div [appFormControlWrap]="form.get('group')" *ngIf="organizations.length > 0">
      <app-select placeholder="{{ 'documents.group' | translate }}" formControlName="group" [options]="groups" [required]="true"></app-select>
    </div>
    <div class="warning-message" *ngIf="!isRestoration"><i class="fa-regular fa-circle-info"></i>&nbsp; {{ 'documents.transferNotUndoable' | translate }}</div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="btn-L btn-filled-cancel" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="btn-L btn-filled" [disabled]="form.invalid || isLoading">
      {{ 'button.' + (isRestoration ? 'restore' : 'transfer') | translate }}
    </button>
  </div>
</form>
