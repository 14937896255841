<app-auth-container>
  <ng-container *ngIf="!wasSent">
    <p class="auth__title mb-3">{{ 'auth.recoverPassword' | translate }}</p>
    <p class="auth__descr">{{ 'auth.fillEmailToRecoverPassword' | translate }}</p>

    <form class="field__form" (ngSubmit)="sendPasswordConfirmation()" [formGroup]="form" novalidate>
      <label class="field__holder" [appFormControlWrap]="form.get('email')">
        <input type="email" class="field" formControlName="email" name="email" autocomplete="email" required appValidEmail />
        <span class="field__placeholder">{{ 'auth.emailToRecoverPassword' | translate }}</span>
      </label>
      <div class="submit-holder mt-0">
        <button type="submit" class="submit" [disabled]="form.invalid || isLoading">{{ 'button.send' | translate }}</button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="wasSent">
    <p class="auth__title mb-3">{{ 'auth.sent' | translate }}</p>
    <p class="auth__descr">{{ 'auth.receivedEmailWithButton' | translate }}</p>

    <p class="auth__descr auth__descr--color-black" [innerHTML]="'auth.recoverStep.first' | translate: { email: this.form.get('email').value }"></p>
    <p class="auth__descr auth__descr--color-black" [innerHTML]="'auth.recoverStep.second' | translate"></p>

    <div class="auth__modal warning" *ngIf="isOutlookEmail(this.form.get('email').value)">
      <figure class="auth__modal-icon"></figure>
      <div class="auth__modal-container">
        <div class="auth__modal-title">{{ 'auth.hotmailOutlookEmailWarning' | translate }}</div>
        <div class="auth__modal-descr">{{ 'auth.hotmailOutlookEmailInstructions' | translate }}</div>
      </div>
    </div>

    <a *ngIf="!secondChance" (click)="sendSecondChance()" class="auth__link mt-3 mb-4">{{ 'button.resendEmail' | translate }}</a>
    <a *ngIf="secondChance" href="/recuperar-senha" class="auth__link mt-3 mb-4">{{ 'auth.notReceivedEmail' | translate }}</a>
  </ng-container>

  <a routerLink="/entrar" queryParamsHandling="preserve" class="auth__link" [class.d-none]="workaroundService.shouldAvoidLoginOrSignup()">{{ 'auth.rememberedPasswordAccessHere' | translate }}</a>
</app-auth-container>
