<div
  class="floating-fields-wrapper forceDefaultColors"
  [class.dragging]="isDraggingField"
  (mousemove)="onDragField($event)"
  (mouseup)="onStopSelectedField()"
  (mouseleave)="onStopSelectedField()"
  (touchmove)="onDragField($event)"
  (touchend)="onStopSelectedField()"
  (touchcancel)="onStopSelectedField(); selectField(null)"
>
  <ng-container *ngFor="let field of parsedFields; trackBy: fieldIdentifier">
    <div
      class="floating-field-shell"
      [ngStyle]="field.styles"
      [style.z-index]="field.isSelected ? 20 : 10"
      [ngClass]="{ newSignatureStyle: field.element === PositionElementEnum.Signature && isNewSignatureStyle }"
    >
      <div
        class="floating-field {{ (field.signature?.font || '').toLowerCase() }}"
        [class.selected]="field.isSelected"
        [class.edit-mode]="enableEdit && !field.signature"
        [ngStyle]="field.innerStyles"
        (mousedown)="onStartDraggingField($event, field)"
        (mouseenter)="selectField(field.originalField)"
        (mouseleave)="selectField(null)"
        (touchstart)="onStartDraggingField($event, field); selectField(field.originalField)"
        (touchend)="onStopRotateField()"
        (touchcancel)="onStopRotateField()"
      >
        <ng-container *ngIf="field.signature && !field.idColor">
          <div class="signature-content" *ngIf="!field.element || field.element === PositionElementEnum.Signature">
            <div [ngClass]="{ 'signature-newSignatureStyle': isNewSignatureStyle }">
              <div class="signature-newSignatureStyle__img" *ngIf="isNewSignatureStyle && isDocumentWhitelabelLoaded">
                <img alt="" [src]="documentWhitelabel?.pdf_logo_mini" />
              </div>
              <div class="" [ngClass]="{ 'signature-newSignatureStyle__content': isNewSignatureStyle }">
                <ng-container>
                  <div class="signature-eletronic" *ngIf="currentFormat(field) === SignatureFormat.Eletronic">
                    {{ 'signature.' + (isNewSignatureStyle ? 'electronicallySignedBy' : 'electronicallySigned') | translate }}
                  </div>

                  <div
                    class="signature-handwriting"
                    *ngIf="
                      currentFormat(field) === SignatureFormat.Handwriting ||
                      (currentFormat(field) === SignatureFormat.Image && !field.signature.signatureImageUrl) ||
                      (currentFormat(field) === SignatureFormat.Draw && !field.signature.signatureDrawImageUrl)
                    "
                  >
                    {{ field.signature.name | signatureName }}
                  </div>

                  <div
                    class="signature-image"
                    *ngIf="
                      (currentFormat(field) === SignatureFormat.Image && field.signature.signatureImageUrl) || (currentFormat(field) === SignatureFormat.Draw && field.signature.signatureDrawImageUrl)
                    "
                    [ngStyle]="{ backgroundImage: 'url(' + (currentFormat(field) === SignatureFormat.Image ? field.signature.signatureImageUrl : field.signature.signatureDrawImageUrl) + ')' }"
                  ></div>
                </ng-container>
                <ng-container *ngIf="isNewSignatureStyle">
                  <div class="signature-newSignatureStyle-name">{{ field.signature.certificate?.name || field.signature.name | signatureNewStyleName }}</div>
                  <div class="signature-newSignatureStyle-date">
                    {{
                      ('signature.dateLabel' | translate) +
                        (field.signature.created_at | ldate: dateFormat:timezone) +
                        ' ' +
                        (field.signature.created_at | ldate: (dateFormat == 'dd/MM/yyyy' ? 'HH:mm' : 'h:mm a'):timezone)
                    }}
                  </div>
                  <div class="signature-newSignatureStyle-id">{{ '#' + field.signature.public_id }}</div>
                  <div class="signature-newSignatureStyle-action">{{ documentService.translateRole(field.signature.actionName) }}</div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="signature-content" *ngIf="field.element === PositionElementEnum.Initials">
            <div class="signature-handwriting initials eletronic" *ngIf="currentFormat(field) === SignatureFormat.Eletronic">{{ field.signature.name | initialsName }}</div>
            <div
              class="signature-handwriting initials"
              *ngIf="
                currentFormat(field) === SignatureFormat.Handwriting ||
                (currentFormat(field) === SignatureFormat.Image && !field.signature.initialsImageUrl) ||
                (currentFormat(field) === SignatureFormat.Draw && !field.signature.initialsDrawImageUrl)
              "
            >
              {{ field.signature.name | initialsName }}
            </div>
            <div
              class="signature-image"
              *ngIf="(currentFormat(field) === SignatureFormat.Image && field.signature.initialsImageUrl) || (currentFormat(field) === SignatureFormat.Draw && field.signature.initialsDrawImageUrl)"
              [ngStyle]="{ backgroundImage: 'url(' + (currentFormat(field) === SignatureFormat.Image ? field.signature.initialsImageUrl : field.signature.initialsDrawImageUrl) + ')' }"
            ></div>
          </div>
          <div class="signature-content text-left" *ngIf="field.element === PositionElementEnum.Name">
            <div>
              <span class="signature-name">{{ field.signature.name || ('signature.name' | translate) }}</span>
              <span *ngIf="!field.signature.name && field.signature.email">&nbsp;({{ field.signature.email }})</span>
            </div>
          </div>
          <div class="signature-content text-left" *ngIf="field.element === PositionElementEnum.Cpf">
            <span class="signature-name" *ngIf="field.signature.cpf">{{ field.signature.cpf }}</span>
          </div>
          <div class="signature-content text-left" *ngIf="field.element === PositionElementEnum.Date">
            <span class="signature-name">{{ field.signature.created_at ? (field.signature.created_at | ldate: 'longDate') : ('signature.signatureDate' | translate) }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="field.idColor">
          <div
            class="rotate"
            [ngClass]="{ active: field.canRotate }"
            (mousedown)="onStartRotateField($event, field)"
            (touchstart)="onStartRotateField($event, field)"
            (touchend)="onStopRotateField(); selectField(null)"
            *ngIf="!qualified"
          >
            <i class="fal fa-sync-alt"></i>
          </div>
          <div class="signature-content newSignatureStyle" *ngIf="field.element === PositionElementEnum.Signature">
            <div class="signature-empty"><i class="fal fa-signature"></i> {{ 'signature.signature' | translate }}</div>
          </div>
          <div class="signature-content" *ngIf="field.element === PositionElementEnum.Initials">
            <div class="signature-empty"><i class="fal fa-spell-check"></i> {{ 'signature.initials' | translate }}</div>
          </div>
          <div class="signature-content" *ngIf="field.element === PositionElementEnum.Name">
            <div class="signature-empty"><i class="fal fa-user"></i> {{ 'signature.name' | translate }}</div>
          </div>
          <div class="signature-content" *ngIf="field.element === PositionElementEnum.Cpf">
            <div class="signature-empty"><i class="fal fa-id-card"></i> {{ 'signature.cpf' | translate }}</div>
          </div>
          <div class="signature-content" *ngIf="field.element === PositionElementEnum.Date">
            <div class="signature-empty"><i class="fal fa-calendar-day"></i> {{ 'signature.signatureDate' | translate }}</div>
          </div>
        </ng-container>
        <div class="floating-field-options">
          <div
            class="floating-field-option"
            (click)="unlinkField(field)"
            [ngbTooltip]="tooltipUnlink"
            container="body"
            *ngIf="!qualified && field.originalField.idLink"
            [placement]="returnPlacementTooltip(field)"
          >
            <i class="fal fa-link-simple-slash"></i>
          </div>
          <div
            class="floating-field-option"
            (click)="unlinkFields(field.originalField)"
            [ngbTooltip]="tooltipUnlinkAll"
            container="body"
            *ngIf="!qualified && field.originalField.idLink"
            [placement]="returnPlacementTooltip(field)"
          >
            <i class="fal fa-link-horizontal-slash"></i>
          </div>
          <div
            class="floating-field-option"
            (click)="openRepeatFieldModal(field.originalField)"
            [ngbTooltip]="tooltipRepeat"
            container="body"
            *ngIf="!qualified && !field.originalField.idLink"
            [placement]="returnPlacementTooltip(field)"
          >
            <i class="fal fa-copy"></i>
          </div>
          <div class="floating-field-option" (click)="removeField(field.originalField)" [ngbTooltip]="tooltipClose" container="body" [placement]="returnPlacementTooltip(field)">
            <i class="fal fa-times"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #tooltipUnlink>{{ 'signature.unlink' | translate }}</ng-template>
<ng-template #tooltipUnlinkAll>{{ 'signature.unlinkAll' | translate }}</ng-template>
<ng-template #tooltipRepeat>{{ 'signature.repeat' | translate }}</ng-template>
<ng-template #tooltipClose>{{ 'signature.remove' | translate }}</ng-template>
