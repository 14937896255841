<!-- MOBILE: -->
<!-- -->
<app-mobile-header-secondary title="{{ 'menu.plansPayment' | translate }}"></app-mobile-header-secondary>
<!--
<div class="mobile mobile__page-in-construction">
  <div class="mobile__modal">
    <figure class="mobile__modal-icon"></figure>
    <div class="mobile__modal-container">
      <div class="mobile__modal-title">Página indisponível no momento</div>
      <div class="mobile__modal-descr">
        Desculpe, a página de planos e pagamentos não está disponível em dispositivos móveis no momento, apenas em telas maiores.
      </div>
    </div>
  </div>
</div>
-->

<app-payment-warning [currentOrder]="currentOrder" *ngIf="currentOrder"></app-payment-warning>

<div class="d-flex flex-column flex-xl-row" *ngIf="!appService.isEnterpriseChild">
  <div class="configuration-plans-row">
    <article class="configuration-item" *ngIf="!isLoading && currentUser?.currentPermissions.change_plan_oz && isSubscriptionActive() && !isGovernmentUser()">
      <div class="configuration-container">
        <div class="configuration-content">
          <p class="configuration-title">
            {{ 'settings.currentPlanOf' | translate }} <span class="highlight" *ngIf="currentUser?.organization?.name">{{ currentUser?.organization?.name }}</span>
          </p>
          <div class="plan__current plan__background">
            <div class="plan__current-item">
              <div class="info">
                <p class="configuration-subtitle featured">
                  {{ currentOrder?.plan_name || currentOrderPlan?.name || ('settings.free' | translate) }}
                </p>
                <p class="configuration-text mb-1">
                  {{ 'settings.' + (currentOrder?.suspended ? 'expiresIn' : 'renewIn') | translate }}
                  <strong>{{
                    (currentOrder?.latest_invoice || [])[0]?.status === 'pending'
                      ? (currentOrder?.latest_invoice?.due_date | ldate)
                      : (currentOrder?.expires_at || currentOrder?.cycled_at || currentUser.subscription.expires_at | ldate)
                  }}</strong>
                </p>
              </div>
              <div class="value featured-purple">
                <small>{{ 'settings.currencyBrazilianSymbol' | translate }}</small>
                <span class="h5">{{ (currentOrder?.price || 0) / 100 || currentOrderPlan?.amount || 0 | lcurrency: ' ' }}</span>
                <span class="configuration-text mb-1"> /{{ getPlansIntervalMap(currentOrderPlan?.interval)?.subject }}</span>
              </div>
            </div>
            <div class="line"></div>
            <div class="plan__current-item">
              <div class="info">
                <p class="configuration-text mb-0 black">
                  <span class="featured">{{ currentOrder?.plan_name || currentOrderPlan?.name || 'Grátis' }}</span>
                  <a href="javascript:void(0)" class="cancel" (click)="!isLoading && triggerCancelSubscription()" *ngIf="!currentOrder?.suspended">{{ 'button.cancel' | translate }}</a>
                </p>
                <p class="configuration-text mb-0">
                  {{ getPlansIntervalMap(currentOrderPlan?.interval)?.title }}
                </p>
              </div>
              <div class="value">
                <span class="configuration-text mb-0">{{ (currentOrder?.price || 0) / 100 || currentOrderPlan?.amount || 0 | lcurrency: 'R$' }}</span>
              </div>
            </div>
            <div class="plan__current-item" *ngIf="additionalGroupsCount() > 0">
              <div class="info">
                <p class="configuration-text mb-0 black">
                  <span class="featured">{{ 'settings.additionalGroups' | translate }}</span>
                  <span>
                    (
                    {{ additionalGroupsCount() }} {{ 'settings.' + (additionalGroupsCount() !== 1 ? 'groups' : 'group') | translate }} &#215; {{ additionalGroupsMonths() }} &nbsp;{{
                      'settings.' + (additionalGroupsMonths() !== 1 ? 'months' : 'month') | translate
                    }}
                    &#215;
                    {{ organizationService.additionalGroupPrice | lcurrency: 'R$' }}
                    )
                  </span>
                </p>
              </div>
              <div class="value">
                <span class="configuration-text mb-0">{{ additionalGroupsCount() * additionalGroupsMonths() * organizationService.additionalGroupPrice | lcurrency: 'R$' }}</span>
              </div>
            </div>
            <div class="plan__current-item total">
              <div class="info">
                <p class="configuration-subtitle featured">
                  {{ 'settings.total' | translate }}
                </p>
              </div>
              <div class="value">
                <span class="subtitle">
                  <strong>{{ checkoutTotal() | lcurrency: ' ' }}</strong
                  >/{{ getPlansIntervalMap(currentOrderPlan?.interval)?.subject }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div class="alert alert-primary" role="alert" *ngIf="currentUser && !currentUser.currentPermissions.change_plan_oz">
      {{ 'settings.noPermissionToChangePlan' | translate }}
    </div>
    <article class="configuration-item" *ngIf="currentUser?.currentPermissions.change_plan_oz">
      <div class="configuration-container">
        <div class="configuration-content">
          <p class="configuration-title ">{{ 'settings.availablePlans' | translate }}</p>

          <div class="configuration-plans">
            <app-switch-tabs
              [options]="[
                [false, translateService.instant('settings.monthly')],
                [true, translateService.instant('settings.annual')]
              ]"
              [(ngModel)]="isYearly"
              (ngModelChange)="setPlanInSale()"
              [ngModelOptions]="{ standalone: true }"
            ></app-switch-tabs>

            <div class="configuration-plans-list">
              <div class="plan-item">
                <p class="plan-item-name">{{ 'settings.free' | translate }}</p>
                <div class="plan-item-price"><span class="plan-item-price__number">&nbsp;</span></div>
                <div class="plan-item-limit" [innerHTML]="'settings.unlimitedDocuments' | translate"></div>
                <button type="button" class="plan-item-button" [disabled]="isLoading || !currentPlan || this.currentOrder?.suspended" (click)="triggerCancelSubscription()">
                  {{ !currentPlan ? ('settings.currentPlan' | translate) : ('button.subscribe' | translate) }}
                </button>
                <div class="plan-item-features">
                  <p [innerHTML]="'settings.unlimitedUsers' | translate"></p>
                  <p [innerHTML]="'settings.unlimitedSigners' | translate"></p>
                </div>
              </div>

              <div class="plan-item">
                <p class="plan-item-name">{{ (planInSale?.promo || planInSale)?.name || ('shared.pro' | translate) }}</p>
                <div class="plan-item-price">
                  <span class="plan-item-price__promo" *ngIf="planInSale?.promo">R$ {{ planInSale.amount / 100 | ldecimal }}</span>
                  <b class="plan-item-price__symbol">R$</b>
                  <span class="plan-item-price__number">{{ (planInSale?.promo || planInSale)?.amount / 100 || (isYearly ? 999 : 99) | ldecimal }}</span>
                  / {{ 'settings.' + (isYearly ? 'year' : 'month') | translate }}
                </div>
                <div class="plan-item-limit" [innerHTML]="'settings.unlimitedDocumentsBold' | translate"></div>
                <button
                  type="button"
                  class="plan-item-button"
                  [disabled]="isLoading || [processingPlan?.id].includes((planInSale?.promo || planInSale)?.id)"
                  [ngClass]="{ disabled: [currentPlan?.id].includes((planInSale?.promo || planInSale)?.id) && !this.currentOrder?.suspended }"
                  (click)="buySelectedPlan(planInSale?.promo || planInSale)"
                >
                  {{
                    planInSale && currentPlan?.id === (planInSale.promo || planInSale).id && !hasGatewaySubscriptionExpired()
                      ? ('settings.currentPlan' | translate)
                      : planInSale && processingPlan?.id === (planInSale.promo || planInSale).id && !hasGatewaySubscriptionExpired()
                      ? ('settings.processing' | translate)
                      : ('button.subscribe' | translate)
                  }}
                </button>
                <div class="plan-item-features">
                  <p [innerHTML]="'settings.unlimitedUsersBold' | translate"></p>
                  <p [innerHTML]="'settings.unlimitedSignersBold' | translate"></p>
                  <p [innerHTML]="'settings.emailCustomization' | translate"></p>
                  <p [innerHTML]="'settings.signatureReminders' | translate"></p>
                  <p [innerHTML]="'settings.dueDateReminders' | translate"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>

  <div class="configuration-plans-row">
    <article class="configuration-item" *ngIf="currentUser?.currentPermissions.change_plan_oz && currentCustomer">
      <div class="configuration-container mb-0">
        <div class="configuration-content">
          <p class="configuration-title ">{{ 'settings.billingInformation' | translate }}</p>
        </div>
      </div>
      <div class="configuration-container mb-2">
        <div class="configuration-content">
          <div class="configuration-plans-info" *ngIf="currentCustomer || currentOrder">
            <div class="info-item mb-2 billingInfo__resume" *ngIf="currentOrder?.card_data?.last_digits || currentOrder?.payment_method">
              <p class="info-item-title">{{ 'settings.paymentMethod' | translate }}</p>
              <button type="button" class="btn btn-sm btn-link billingInfo__button" *ngIf="!currentOrder?.suspended" (click)="openEditPaymentInfoModal(EditPaymentInfoModalStep.paymentMethod)">
                {{ 'button.edit' | translate }}
              </button>
              <div *ngIf="currentOrder?.card_data?.last_digits || currentOrder?.payment_method === PaymentMethodsEnum.CreditCard">
                <div class="info-item-icon">
                  <i class="fal fa-credit-card"></i>
                  <span>{{ 'settings.creditCard' | translate }}</span>
                </div>

                <ng-container *ngIf="currentOrder?.card_data?.last_digits && currentOrder?.card_data?.month && currentOrder?.card_data?.year">
                  <p class="mb-0 number">{{ 'settings.last4Digits' | translate: { last4: currentOrder?.card_data?.last_digits } }}</p>
                  <p class="mb-0">
                    {{ 'settings.validUntil' | translate }}
                    {{ (currentOrder?.card_data?.month < 10 ? '0' : '') + currentOrder?.card_data?.month + '/' + currentOrder?.card_data?.year }}
                  </p>
                </ng-container>
              </div>
              <div class="info-item-icon" *ngIf="currentOrder?.payment_method === PaymentMethodsEnum.Boleto">
                <i class="fal fa-barcode-alt"></i>
                <span>{{ 'settings.bankSlip' | translate }}</span>
              </div>
              <div class="info-item-icon" *ngIf="currentOrder?.payment_method === PaymentMethodsEnum.Pix">
                <img src="/assets/pix.png" alt="" />
                <span>{{ 'settings.pix' | translate }}</span>
              </div>
            </div>
            <div class="info-item billingInfo__resume" *ngIf="currentCustomer">
              <p class="info-item-title">{{ 'settings.billingDetails' | translate }}</p>
              <button type="button" class="btn btn-sm btn-link billingInfo__button" (click)="openEditPaymentInfoModal(EditPaymentInfoModalStep.paymentInfo)">{{ 'button.edit' | translate }}</button>
              <div class="info-item-text" *ngIf="currentCustomer?.created_at">
                {{ currentCustomer.name }}<br />
                {{ currentCustomer.cpf || currentCustomer.cnpj | cpfCnpj }}<br />
                {{ currentCustomer.street }}, {{ currentCustomer.number }}<br />
                {{ currentCustomer.district }} - {{ currentCustomer.city }}, {{ currentCustomer.state }}<br />
                {{ currentCustomer.zip_code | cep }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="configuration-container mb-4">
        <div class="configuration-content">
          <label class="field__holder billingInfo__email" *ngIf="currentCustomer?.created_at">
            <button type="button" class="btn btn-sm btn-link billingInfo__button" (click)="openEditPaymentInfoModal(EditPaymentInfoModalStep.paymentEmail)">{{ 'button.edit' | translate }}</button>
            <input type="text" class="field" [value]="currentCustomer.email" disabled />
            <span class="field__placeholder">{{ 'settings.billingEmail' | translate }}</span>
          </label>
        </div>
      </div>
    </article>

    <article class="configuration-item" *ngIf="currentUser?.currentPermissions.view_invoices_oz && invoices?.length > 0">
      <div class="configuration-container">
        <div class="configuration-content">
          <p class="configuration-title ">{{ 'settings.invoicesAndReceipts' | translate }}</p>

          <div class="configuration-sub_item mb-4">
            <div class="configuration-info">
              <span class="configuration-info-data">{{ 'settings.publicAddressForInvoices' | translate }}</span>
            </div>
            <div class="configuration-value">
              <input type="text" class="configuration-value-show" [value]="invoicesPublicUrl()" readonly #invoiceUrlInputElement />
              <a class="configuration-value-copy text-decoration-none" (click)="copyInvoicesLink()" [ngxClipboard]="invoiceUrlInputElement"></a>
            </div>
          </div>

          <div class="invoices" id="invoices">
            <ng-container *ngFor="let invoice of invoices">
              <div class="invoices__list" *ngIf="invoice.status != 'creating' && invoice.price > 0">
                <div
                  class="invoices__list-item"
                  [ngClass]="{
                    status__paid: ['paid', 'authorized'].includes(invoice.status),
                    status__canceled: ['canceled', 'disabled'].includes(invoice.status) || !invoice.status,
                    status__warning: ['pending', 'captured', 'warning', 'refunded'].includes(invoice.status) || !invoice.status,
                    status__error: ['expired', 'error'].includes(invoice.status),
                    clickable: invoiceUrl(invoice) || nfseUrl(invoice)
                  }"
                  (click)="isMobile ? openUrl(invoiceUrl(invoice) || nfseUrl(invoice)) : null"
                >
                  <div class="product">
                    <div class="product__name">{{ invoice.description }}</div>
                    <div class="product__date">{{ invoice.due_date | ldate: 'mediumDate' }}</div>
                  </div>
                  <div class="status">
                    <span class="status__label">{{ this.translateService.instant('status_' + invoice.status) }}</span>
                  </div>
                  <div class="value">{{ invoice.price / 100 | lcurrency: 'R$' }}</div>
                  <div class="buttons">
                    <div class="autentique-button" *ngIf="invoiceUrl(invoice)" (click)="openUrl(invoiceUrl(invoice))">
                      {{ 'settings.accessInvoice' | translate }}
                    </div>
                    <div ngbDropdown *ngIf="nfseUrl(invoice)">
                      <label ngbDropdownToggle class="autentique-button autentique-button--edit">{{ 'settings.receipt' | translate }}</label>
                      <div ngbDropdownMenu>
                        <button class="downloadOption" (click)="openUrl(nfseUrl(invoice))" ngbDropdownItem>{{ 'settings.download' | translate }} PDF</button>
                        <button class="downloadOption" (click)="openUrl(nfseUrl(invoice, 'xml'))" ngbDropdownItem>{{ 'settings.download' | translate }} XML</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="more" *ngIf="!isLoading && !invoiceIsLastPage">
              <button type="button" class="autentique-button autentique-button--edit" (click)="loadInvoices({ page: invoicePageNumber + 1 })">{{ 'settings.loadOlderInvoices' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</div>
