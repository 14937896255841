<input
  type="tel"
  [class]="inputClass"
  [name]="name"
  [value]="partialValue"
  [disabled]="disabled"
  [autocomplete]="autocomplete"
  [required]="required"
  [mask]="mask"
  (keyup)="setValue(partialValue)"
  (change)="setValue(partialValue); onTouch()"
  #phoneInputElement
/>
