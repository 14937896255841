import { AfterContentInit, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { filter, finalize, map, pairwise, startWith } from 'rxjs/operators';

import { ContextEnum, SlimDocument, User } from '@app/models';
import { ErrorHandlerService, OrganizationService, UserService } from '@app/services';
import { SelectOption } from '@app/shared';

export type ModalResult = boolean;
export interface ModalPublicProperties {
  documents: SlimDocument[];
  isRestoration?: boolean;
  context?: ContextEnum;
}

@Component({
  selector: 'app-transfer-documents-modal',
  templateUrl: './transfer-documents-modal.component.html',
  styleUrls: ['./transfer-documents-modal.component.scss']
})
export class TransferDocumentsModalComponent implements ModalPublicProperties, AfterContentInit {
  form!: FormGroup;
  isLoading = false;
  currentUser: User;
  organizations: SelectOption[] = [];
  groups: SelectOption[] = [];
  @Input() documents: SlimDocument[];
  @Input() isRestoration: boolean;
  @Input() currentGroupId: number;
  @Input() context: ContextEnum = ContextEnum.User;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {
    this.form = this.formBuilder.group({ organization: null, group: null });
  }

  ngAfterContentInit() {
    forkJoin([this.userService.getCurrentUser(), this.organizationService.getOrganizations()]).subscribe(([user, organizations]) => {
      this.currentUser = user;
      this.organizations = organizations.map(org => ({ key: org.id, value: org.name, height: 46, fontSize: 16 }));

      this.form
        .get('organization')
        .valueChanges.pipe(
          startWith(0),
          pairwise(),
          filter(([oldValue, newValue]) => newValue && newValue !== oldValue),
          map(([oldValue, newValue]) => newValue)
        )
        .subscribe(orgId => {
          this.isLoading = true;
          this.organizationService
            .slimOrganizationGroups({ page: 1, limit: 60, organizationId: parseInt(orgId) }, { fetchPolicy: 'cache-first' })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
              groups => {
                this.groups = groups.data.map(group => ({ key: group.id, value: group.name, height: 46, fontSize: 16 }));
                if (this.form.get('group').value) {
                  this.form.get('group').setValue(null);
                }
              },
              error => this.errorHandlerService.handle(error)
            );
        });

      this.form.get('organization').setValue(this.currentUser.organization.id);
    });
  }

  transfer() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    const organizationId = this.form.get('organization').value;
    const groupId = this.form.get('group').value;

    this.organizationService
      .transferDocuments(
        this.documents.map(doc => doc.id),
        { organizationId, groupId, context: this.context, ...(this.currentGroupId ? { currentGroupId: this.currentGroupId } : {}) }
      )
      .pipe(
        map(() => true),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handle(error)
      );
  }
}
