import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { AuthenticationMethod } from '@app/models';
import { WhitelabelService, AuthenticationService, ErrorHandlerService } from '@app/services';
import { LoaderService } from '@app/shared/loader/loader.service';
import { CredentialsService } from '@app/core/credentials.service';

@Component({
  selector: 'app-waitingroom',
  templateUrl: './waitingroom.component.html',
  styleUrls: ['./waitingroom.component.scss']
})
export class WaitingroomComponent implements OnInit {
  token: string;

  constructor(
    public whitelabelService: WhitelabelService,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit(): void {
    // listens to message from iframe, expects a token message
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        // needs to implement origin check once we have the correct URL
        // if (!['INSERT URL'].includes(event.origin)) {
        //   return;
        // }
        this.getToken(event);
      },
      false
    );
  }

  getToken(event: any) {
    // Decodes JWT to get email for acc verification, redirects user to verification page or target url
    this.token = event.data.message;
    this.loaderService.disableHttpLoader = true;
    const enterpriseToken = this.credentialsService.decodeJwt(this.token);
    of(null)
      .pipe(
        switchMap(() => this.authenticationService.logout()),
        switchMap(() => this.authenticationService.login({ enterprise_token: this.token }, AuthenticationMethod.Enterprise)),
        finalize(() => (this.loaderService.disableHttpLoader = false))
      )
      .subscribe(
        () => {
          this.router.navigate([this.activatedRoute.snapshot.queryParams.redirect || '/'], { replaceUrl: true, queryParams: this.activatedRoute.snapshot.queryParams });
        },
        error => {
          if (error?.status === 424) {
            this.router.navigate(['/confirmacao'], {
              queryParams: { email: enterpriseToken.email, redirect: this.activatedRoute.snapshot.queryParams.redirect || '/' },
              state: { enterpriseToken: this.token }
            });
          } else {
            this.errorHandlerService.handle(error);
          }
        }
      );
  }
}
