<article class="configuration-item" *ngIf="currentUser?.currentPermissions.change_plan_oz && credits && !appService.isEnterpriseChild">
  <div class="payment-warning pending" *ngIf="isPendingCreditInvoice(invoice)">
    <div class="payment-warning__body">
      <div class="payment-warning__title">{{ 'shared.invoicePending' | translate }}</div>
      <div>{{ 'shared.payInvoiceCredit' | translate }}</div>
      <div class="payment-warning__extra">
        <strong>{{ 'shared.dueDate' | translate }} {{ invoice.due_date | ldate }}</strong>
      </div>
    </div>
    <div class="payment-warning__links">
      <a [href]="invoice.url_payment" class="btn-M btn-filled" target="_blank">{{ 'shared.accessInvoice' | translate }}</a>
    </div>
  </div>

  <div class="configuration-title mb-1">
    {{ 'settings.creditsValidationOf' | translate }} <span class="highlight">{{ currentUser.organization?.name }}</span>
  </div>

  <div class="configuration-container">
    <div class="configuration-content credits">
      <p class="configuration-title mb-2" [innerHTML]="'settings.creditsTitle' | translate"></p>
      <p class="configuration-subtitle" [innerHTML]="'settings.creditsDesc' | translate"></p>

      <div class="credits__list">
        <a
          class="credits__list-item text-decoration-none"
          [attr.data-best-value]="translateService.instant('settings.bestValue')"
          [attr.data-promo-value]="translateService.instant('settings.blackFriday')"
          *ngFor="let credit of credits"
          (click)="buySelectedCredit(credit)"
          [ngClass]="{ best: credit.best, 'promo forceDefaultColors': credit.amount_promo && credit.is_promo && userService.isEventSale() }"
        >
          <i class="fa-solid fa-shield-check"></i>
          <div class="credits__desc">
            <p class="mb-0">{{ credit.description }}</p>
          </div>
          <div class="credits__info">
            <p class="mb-0 value">
              <span>{{ 'settings.currencyBrazilianSymbol' | translate }}</span> {{ ((credit.is_promo ? credit.amount_promo : credit.amount) * credit.quantity) / 100 }}
            </p>
            <p class="mb-0">{{ (credit.is_promo ? credit.amount_promo : credit.amount) / 100 | lcurrency: 'R$' }}{{ 'settings.credit' | translate }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="configuration-line" *ngIf="creditLogs?.length > 0"></div>
  <div class="configuration-container" *ngIf="creditLogs?.length > 0">
    <div class="credits__history">
      <p class="configuration-title mb-2">{{ 'settings.credit_history' | translate }}</p>
      <table class="credits__history-table">
        <thead>
          <tr>
            <th>{{ 'settings.date' | translate }}</th>
            <th>{{ 'settings.credits' | translate }}</th>
            <th>{{ 'settings.user' | translate }}</th>
            <th>{{ 'settings.description' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let creditLog of creditLogs">
            <td>{{ creditLog.created_at | dateTime }}</td>
            <td [ngClass]="{ negative: creditLog.amount < 0, positive: creditLog.amount >= 0 }">{{ creditLog.amount }}</td>
            <td title="{{ creditLog.user_email }}">{{ creditLog.user_email || creditLog.user_phone }}</td>
            <td title="{{ creditLog.verifications }}">{{ creditLog.verifications }}</td>
            <td>
              <ng-container *ngIf="creditLog.document_id; else invoiceLink">
                <a [routerLink]="['/documentos', creditLog.document_id]" target="_blank" class="document-link">
                  {{ 'settings.document' | translate }}
                  <i class="fa-regular fa-arrow-up-right-from-square"></i>
                </a>
              </ng-container>
              <ng-template #invoiceLink>
                <ng-container *ngIf="creditLog.url_nfse">
                  <a [href]="creditLog.url_nfse" target="_blank" class="document-link">
                    {{ 'settings.receipt' | translate }}
                    <i class="fa-regular fa-arrow-up-right-from-square"></i>
                  </a>
                </ng-container>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center mt-1">
        <button type="button" class="btn-M btn-text" *ngIf="!creditLogIsLastPage" (click)="loadCreditLogs({ page: creditLogPageNumber + 1 })">{{ 'button.loadMore' | translate }}</button>
      </div>
    </div>
  </div>
</article>
