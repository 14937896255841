import * as def from 'src/generated/graphql.default';
import { TemplateRef } from '@angular/core';

export type Maybe<T> = T | null;

export enum AuthenticationMethod {
  Email = 'email',
  Google = 'google2',
  Facebook = 'facebook',
  Sms = 'sms',
  Whatsapp = 'wa',
  Passwordless = 'passwordless',
  Microsoft = 'microsoft',
  Enterprise = 'enterprise'
}
export enum SignatureFormat {
  Draw = 'd',
  Handwriting = 's',
  Eletronic = 'e',
  Image = 'i'
}
export enum ListFormat {
  Box = 'box',
  List = 'list'
}
export enum ListSorting {
  CreatedAt = 'created_at',
  Name = 'name',
  UpdatedAt = 'updated_at'
}
export enum DocumentStatus {
  Pending = 'pending',
  Signed = 'signed',
  Rejected = 'rejected',
  Expired = 'expired',
  NotSigned = 'notSigned',
  Warning = 'warning'
}
export enum DocumentStatusFilter {
  Pending = 'filter-pending',
  Signed = 'filter-signed',
  Rejected = 'filter-rejected',
  NotSigned = 'filter-not-signed'
}
export enum SignerMainAttributes {
  Email = 'email',
  Name = 'name',
  Sms = 'sms',
  Whatsapp = 'whatsapp',
  Phone = 'phone'
}
export enum EmailTemplates {
  Default = 'padrao',
  Classic = 'classico',
  Corporate = 'empresarial',
  Clean = 'clean'
}
export enum TemplateAttributeTypes {
  Text = 'text',
  Email = 'email',
  Longtext = 'longtext',
  Cpf = 'cpf',
  Cnpj = 'cnpj',
  Cep = 'cep',
  Phone = 'phone',
  Date = 'date',
  Number = 'number',
  Hour = 'hour'
}

enum SecurityVerificationMapEnum {
  SecurityVerificationManual = 'SECURITY_VERIFICATION_MANUAL',
  SecurityVerificationSms = 'SECURITY_VERIFICATION_SMS',
  SecurityVerificationUpload = 'SECURITY_VERIFICATION_UPLOAD',
  SecurityVerificationLive = 'SECURITY_VERIFICATION_LIVE',
  SecurityVerificationPfFacial = 'SECURITY_VERIFICATION_PF_FACIAL'
}
export const CreditTypeEnum = { ...def.DeliveryMethodEnum, ...SecurityVerificationMapEnum, DocumentLifecycleRescue: 'DOCUMENT_LIFECYCLE_RESCUE' };
export interface FloatingField {
  position: { x: number; y: number; z: number; angle?: number };
  identification?: string;
  element?: def.PositionElementEnum;
  idColor?: string;
  idLink?: number;
  angle?: number;
  signature?: {
    format: string;
    initialsFormat: string;
    font: string;
    signatureImageUrl: string;
    initialsImageUrl: string;
    signatureDrawImageUrl: string;
    initialsDrawImageUrl: string;
    name: string;
    email: string;
    cpf: string;
    created_at: string;
    public_id: string;
    actionName: Signer['action'];
    certificate?: {
      type?: string;
      email?: string;
      name?: string;
      document?: string;
    };
  };
}
export type CustomSignerInput = SignerInput & {
  _extra: {
    signerMainAttribute: SignerMainAttributes;
    documentType?: string;
    phoneNumber?: string;
    isLocked?: boolean;
    isSelected?: boolean;
    showOptions?: boolean;
    showSmsVerification?: boolean;
    showSecurityVerifications?: boolean;
    showCpfVerification?: boolean;
  };
  sms?: string;
  whatsapp?: string;
};

export interface CreditCard {
  card_number: string;
  full_name: string;
  expiration: string;
  cvv: string;
}
export interface ZipCodeInfo {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}
export enum FloatingFieldRepeatEnum {
  ALL_PAGES,
  ALL_PAGES_BUT_LAST
}
export enum DocumentsListColumns {
  Name = 'name',
  Status = 'status',
  Signers = 'signers',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}
export enum AlertButtonType {
  confirm,
  close,
  confirmExtra
}

export enum LanguageEnum {
  EN_US = 'en-US',
  PT_BR = 'pt-BR'
}

export interface Country {
  name: string;
  code: string;
}

export interface Timezone {
  timezone: string;
  gmt: string;
}

export interface MembersAndGroups {
  id: any;
  name: string;
  type: 'member' | 'group';
}

export const ExcludePlans = [
  'EF94B8E8048844B99E804E251F725EEF', // anual prod
  '917203AFBBCF496E9A840B136E87EF7D', // anual dev
  'corporativo_anual'
];

export interface SelectOption {
  key: any;
  value: any;
  tooltip?: string | TemplateRef<any>;
  height?: number;
  fontSize?: number;
  limitWidth?: number;
  credits?: number;
  icon?: string;
  hidden?: boolean;
}

export interface EmailTemplateItem {
  type: def.EmailTemplateTypeEnum;
  label: string;
  ngModel: number;
  options: SelectOption[];
}

export type Organization = def.UserOrganizationFragment;
export type OrganizationMemberPermissions = def.MemberPermissionsFragment;
export type User = def.UserFragment & { currentPermissions: OrganizationMemberPermissions; newHash: string };
export type Signer = { user?: def.SignerFragment['user'] & def.SignerFragment['user_data'] } & def.SignerFragment;
export type Document = { signatures?: Maybe<Signer[]> } & def.AuthDocumentFragment;
export type Folder = def.FolderFragment;
export type SignatureToken = def.SignatureTokenFragment;
export type SlimSignature = { user?: def.SlimDocumentFragment['signatures'][0]['user'] & def.SlimDocumentFragment['signatures'][0]['user_data'] } & def.SlimDocumentFragment['signatures'][0];
export type SlimDocument = { signatures?: Maybe<SlimSignature[]> } & def.SlimDocumentFragment;
export type SlimTemplate = def.SlimTemplateFragment;
export type DocumentInput = def.DocumentInput;
export type SignerInput = def.SignerInput;
export type OrganizationMember = def.OrganizationMemberFragment;
export type OrganizationGroup = def.OrganizationGroupFragment;
export type SlimOrganizationGroup = def.SlimOrganizationGroupsPageFragment['data'][0];
export type OrganizationGroupPermissions = def.GroupPermissionsFragment;
export type EmailTemplate = def.EmailTemplateFragment;
export type Referral = def.ReferralsQuery['referrals'];
export type OrganizationMemberInvitation = def.OrganizationMembersInvitationsPageFragment['data'][0];
export type SlimEmailTemplate = Pick<def.EmailTemplates, 'id' | 'name' | 'type'>;

export {
  Template,
  AttributeInput,
  OrderBy,
  DataSourceEnum,
  SignatureConfigInput,
  SignatureInput,
  ContextEnum,
  OrderByEnum,
  FooterEnum,
  ActionEnum,
  UserInput,
  OrganizationInput,
  PasswordInput,
  ReminderEnum,
  PositionElementEnum,
  Contact,
  UserSignatureInput,
  FontEnum,
  DocumentStatusEnum,
  SignatureAppearanceEnum,
  PadesActionEnum,
  SecurityVerificationEnum,
  DocumentReportColumnEnum,
  SecurityVerificationType,
  SignatureTypesEnum,
  DeliveryMethodEnum,
  CreditType,
  CreditPackType,
  SecurityVerificationPayloadType,
  SecurityVerificationFileTypeEnum,
  CloudSignaturesEnum,
  EmailTemplateTypeEnum,
  Whitelabel,
  WhitelabelSendgridDns,
  Plan,
  Order,
  Customer,
  CustomerInput,
  AddressInput,
  PaymentMethodsEnum,
  TypeEnum as SignatureTypeEnum,
  Token as ApiToken,
  SlimOrganizationFragment as SlimOrganization,
  VerificationsFragment as SecurityVerification,
  WebhookFormatEnum,
  LocaleInput,
  DateFormatEnum,
  UpdateDocumentLocaleInput,
  Locale,
  EmailTemplatesInput,
  EmailTemplatesContentInput,
  SignatureHistory,
  CreditLogType
} from 'src/generated/graphql.default';
export { Invoice, PublicSignerHistoryFragment as SignerHistory, SignerHistory as DocumentSignerHistory } from 'src/generated/graphql.public';

export * from './graphql-response.model';
