<div class="body-holder" *ngIf="route.snapshot.params.id">
  <aside class="sidebar-left" [ngClass]="{ 'd-none d-xl-block': showPdfViewer }">
    <app-document-sidebar-header [hideCurrentUser]="true"></app-document-sidebar-header>

    <main class="sidebar__wrapper">
      <div class="sidebar__wrapper-options">
        <div class="sidebar__wrapper-option" [class.sidebar__wrapper-option--selected]="sidebarContainer === 'signers'" (click)="changeSidebarContainer('signers')">
          <i class="fal fa-feather-alt"></i>{{ 'audit.signatories' | translate }}
        </div>
        <div class="sidebar__wrapper-option" [class.sidebar__wrapper-option--selected]="sidebarContainer === 'history'" (click)="changeSidebarContainer('history')">
          <i class="fal fa-history"></i>{{ 'audit.history' | translate }}
        </div>
      </div>
      <p class="sortable-alert" *ngIf="document?.sortable && sidebarContainer === 'signers'"><i class="fal fa-info-circle"></i> {{ 'audit.thisDocumentMustBeSignedInSpecificOrder' | translate }}</p>
      <div class="sidebar-signers-list" *ngIf="sidebarContainer === 'signers'">
        <ng-container [ngTemplateOutlet]="signersTemplate"></ng-container>
      </div>
      <app-document-history-sidebar [class.d-none]="sidebarContainer !== 'history'" [lockUserData]="document?.configs.lock_user_data" #historyComponent></app-document-history-sidebar>
    </main>
  </aside>
  <section class="document" [ngClass]="{ 'showing-extra': isShowingExtra(), 'showing-pdf': showPdfViewer }">
    <div class="document-mobile-head" *ngIf="!isShowingExtra()">
      <div class="document-mobile-head--logo">
        <a [href]="appService.panelPath('/')">
          <img [src]="whitelabelService.whitelabel.logo || 'assets/Autentique.svg'" alt="" />
        </a>
      </div>
      <div class="document-mobile-head--body" *ngIf="document">
        <div class="document-mobile-head--body-icon">
          <i class="fal fa-file-pdf"></i>
        </div>
        <div>
          <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
          <div class="document-head-status" [ngClass]="{ signed: documentService.isSigned(document) }">
            <ng-container [ngTemplateOutlet]="documentStatusTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="document-mobile-head fixed-top" *ngIf="isShowingExtra()">
      <div class="p-2">
        <div>
          <a href="javascript:void(0)" (click)="goBack()"> <i class="fal fa-chevron-left mr-1"></i> {{ 'button.back' | translate }} </a>
        </div>
        <h2 class="document-head-name text-center">
          {{ returnShowExtraName() }}
        </h2>
      </div>
    </div>

    <div class="document-warning" *ngIf="document?.is_blocked">
      <div class="document-warning-title"><i class="fal fa-exclamation-triangle"></i>&nbsp; {{ 'audit.securityNotice' | translate }}</div>
      <div class="document-warning-descr">
        {{ 'audit.thisDocumentHasBeenBlockedDueToFraudSuspicion' | translate }}<br />
        {{ 'audit.forMoreInformationContactUsAt' | translate }} <a href="mailto:contato@autentique.com.br">contato@autentique.com.br</a>
      </div>
    </div>
    <div class="document-sandbox" *ngIf="document?.sandbox">
      <div class="document-sandbox-title">{{ 'audit.sandboxTestDocument' | translate }}</div>
      <div class="document-sandbox-descr">{{ 'audit.thisDocumentWasCreatedAsASandboxTestAndHasNoLegalValue' | translate }}</div>
    </div>

    <div class="document-head" *ngIf="document">
      <a href="javascript:void(0)" class="document-head-pdf-back d-none" [ngClass]="{ 'd-inline-block d-xl-none': showPdfViewer }" (click)="goBack()">
        <i class="fal fa-chevron-left"></i> {{ 'button.back' | translate }}
      </a>
      <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
      <div class="document-head-status" [ngClass]="{ signed: documentService.isSigned(document), 'd-none d-xl-flex': showPdfViewer }">
        <ng-container [ngTemplateOutlet]="documentStatusTemplate"></ng-container>
      </div>
      <div class="document-head-extra" [ngClass]="{ 'd-none d-xl-flex': showPdfViewer }">
        <div class="document-head-extra--column">
          <a href="javascript:void(0)" class="document-head-extra--column-copy" ngxClipboard [cbContent]="document.hashes.sha2"><i class="fal fa-copy"></i></a>
          <div class="document-head-extra--column-title"><i class="fal fa-key"></i> {{ 'audit.sha256Hash' | translate }}</div>
          <div>{{ document.hashes.sha2 }}</div>
        </div>
        <div class="document-head-extra--column">
          <a href="javascript:void(0)" class="document-head-extra--column-copy" ngxClipboard [cbContent]="document.id"><i class="fal fa-copy"></i></a>
          <div class="document-head-extra--column-title"><i class="fal fa-hashtag"></i> {{ 'audit.documentIdentifier' | translate }}</div>
          <div>{{ document.id }}</div>
        </div>
      </div>
    </div>

    <app-pdf-viewer
      [pdfUrl]="!documentService.isPastLifecycle(document) && document?.files.original"
      [qualified]="document?.qualified"
      [isNewSignatureStyle]="document?.new_signature_style"
      [documentFooter]="document?.new_signature_style && document?.footer"
      [floatingFields]="floatingFields"
      [documentWhitelabel]="documentWhitelabel"
      [isDocumentWhitelabelLoaded]="isDocumentWhitelabelLoaded"
      [document]="document"
      [ngClass]="{ 'd-none d-xl-block': !showPdfViewer && !documentService.isPastLifecycle(document) }"
      [ngStyle]="documentService.isPastLifecycle(document) ? { display: 'none' } : {}"
      #pdfViewer
    ></app-pdf-viewer>
    <app-document-lifecycle-warning [(document)]="document" [ngClass]="{ 'd-none d-xl-block': !showPdfViewer }" *ngIf="documentService.isPastLifecycle(document)"></app-document-lifecycle-warning>
    <div class="document-options d-xl-none" [ngClass]="{ 'd-none': isShowingExtra(), 'd-lg-flex': !showPdfViewer }">
      <ng-container [ngTemplateOutlet]="optionsTemplate"></ng-container>
    </div>
    <div class="document-options d-lg-none" [ngClass]="{ 'd-none': !showDownloadOptions }">
      <ng-container [ngTemplateOutlet]="downloadOptionsTemplate"></ng-container>
    </div>
    <div class="p-2 d-lg-none" [ngClass]="{ 'd-none': !showSigners }">
      <ng-container [ngTemplateOutlet]="signersTemplate"></ng-container>
    </div>
    <div class="p-2 d-lg-none" [ngClass]="{ 'd-none': !showHistory }">
      <app-document-history-sidebar [lockUserData]="document?.configs.lock_user_data" #historyComponentMobile></app-document-history-sidebar>
    </div>
  </section>
  <aside class="sidebar-right">
    <div class="sidebar-right-wrapper">
      <div class="sidebar-right-title">{{ 'audit.menu' | translate }}</div>
      <ng-container [ngTemplateOutlet]="optionsTemplate"></ng-container>
    </div>
  </aside>
</div>

<div class="verification-holder" *ngIf="!route.snapshot.params.id">
  <form class="verification-form" [formGroup]="verificationForm" (ngSubmit)="verify()" novalidate>
    <div class="verification__head"><img [src]="whitelabelService.whitelabel.logo || 'assets/Autentique.svg'" alt="" /></div>
    <p class="verification__title">{{ 'audit.biometricVerification' | translate }}</p>
    <p class="verification__description">{{ 'audit.enterYourCodeInTheFieldBelowToStartYourVerification' | translate }}</p>

    <label class="field__holder" [appFormControlWrap]="verificationForm.get('code')">
      <input
        type="text"
        class="field"
        formControlName="code"
        name="code"
        autocomplete="none"
        role="presentation"
        autocorrect="off"
        spellcheck="false"
        (input)="setUppercase(verificationForm.get('code'))"
        required
      />
      <span class="field__placeholder">{{ 'audit.code' | translate }}</span>
    </label>

    <div class="centermodal__button-group mt-5">
      <button type="button" class="centermodal__button d-none"></button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || verificationForm.invalid">{{ 'button.forward' | translate }}</button>
    </div>
  </form>
</div>

<ng-template #titleTemplate>
  <h2 class="document-head-name">{{ document.name }}</h2>
  <p class="document-head-date">
    {{ document.created_at | dateTime }}
    <span *ngIf="document.expiration_at">({{ documentService.isExpired(document) ? ('audit.expired' | translate) : ('audit.expires' | translate) }} {{ document.expiration_at | dateTime }})</span>
  </p>
</ng-template>

<ng-template #documentStatusTemplate>
  <i class="fal fa-{{ documentService.isSigned(document) ? 'check-square' : 'exclamation-square' }}"></i>
  {{ 'audit.signedBy' | translate }} <strong>{{ (document.signatures | filterSignedSigners).length }}</strong> {{ 'audit.of' | translate }}
  <strong>{{ (document.signatures | filterSigners).length }}</strong>
  {{ (document.signatures | filterSigners).length === 1 ? ('audit.signatory' | translate) : ('audit.signatories' | translate | lowercase) }}
</ng-template>

<ng-template #signersTemplate>
  <div
    *ngFor="let signer of document?.signatures; let index = index"
    class="sidebar-left-signers-item"
    [class.pending]="document.sortable && index > documentService.getNextSignerIndex(document)"
    [class.to-sign-target]="document.sortable && documentService.isSignerTurn(document, signer)"
  >
    <div class="sidebar-left-signers-order" *ngIf="document.sortable">
      <div class="sidebar-left-signers-order--num">{{ index + 1 }}</div>
    </div>
    <app-signer-card
      [signer]="signer"
      [sortable]="document.sortable"
      [isSignerTurn]="documentService.isSignerTurn(document, signer)"
      [documentAuthorId]="document.author.id"
      [forceAutenticat]="document?.signatures.length > 20"
      (certificateClick)="openCertificateModal($event)"
      (verifyBiometryClick)="openVerificationDataModal($event)"
    ></app-signer-card>
  </div>
</ng-template>

<ng-template #optionsTemplate>
  <a class="menu-option d-lg-none" href="javascript:void(0)" (click)="showSigners = true; changeSidebarContainer('signers')">
    <div class="menu-option--icon"><i class="fal fa-feather-alt"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.checkSignatures' | translate }}</div>
      <div>{{ 'audit.viewSignatoriesAndSignatureStatus' | translate }}</div>
    </div>
  </a>
  <a class="menu-option d-lg-none" href="javascript:void(0)" (click)="showHistory = true; changeSidebarContainer('history')">
    <div class="menu-option--icon"><i class="fal fa-history"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.checkHistory' | translate }}</div>
      <div>{{ 'audit.viewEventsThatOccurredInThisDocument' | translate }}</div>
    </div>
  </a>
  <a class="menu-option d-lg-none" href="javascript:void(0)" (click)="showDownloadOptions = true" *ngIf="!documentService.isPastLifecycle(document)">
    <div class="menu-option--icon"><i class="fal fa-cloud-download-alt"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.downloadAuditFiles' | translate }}</div>
      <div>{{ 'audit.downloadSignedAuditFilesAndOriginalFileSentToAutentique' | translate }}</div>
    </div>
  </a>
  <a class="menu-option d-xl-none" href="javascript:void(0)" (click)="showPdfViewer = true; pdfViewer && pdfViewer.refreshFloatingFields()">
    <div class="menu-option--icon"><i class="fal fa-eye"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.viewDocument' | translate }}</div>
      <div>{{ 'audit.viewContentOfTheSignedDocument' | translate }}</div>
    </div>
  </a>
  <ng-container *ngIf="!documentService.isPastLifecycle(document)" [ngTemplateOutlet]="downloadOptionsTemplate"></ng-container>
  <a *ngIf="appService.isAutentiqueDomain()" class="menu-option" [href]="appService.panelPath('/documentos/' + document?.id)" target="_blank">
    <div class="menu-option--icon"><i class="fal fa-external-link"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.accessDocumentInAutentique' | translate }}</div>
      <div>{{ 'audit.accessPageInAutentiqueDashboard' | translate }}</div>
    </div>
  </a>
</ng-template>

<ng-template #downloadOptionsTemplate>
  <ng-container *ngIf="document && !document.qualified">
    <a class="menu-option" target="_blank" [ngClass]="{ 'd-none d-lg-inline-flex': !showDownloadOptions }" [href]="documentService.signedDocumentUrl(document)">
      <div class="menu-option--icon"><i class="fal fa-file-contract"></i></div>
      <div class="menu-option--body">
        <div class="menu-option--body-title">{{ 'audit.downloadSignedPDF' | translate }}</div>
        <div>{{ 'audit.PDFWithAnnotationsAndAuditPageAtTheEndOfFile' | translate }}</div>
      </div>
    </a>
    <a class="menu-option" target="_blank" [ngClass]="{ 'd-none d-lg-inline-flex': !showDownloadOptions }" [href]="document?.files?.certified">
      <div class="menu-option--icon"><i class="fal fa-file-certificate"></i></div>
      <div class="menu-option--body">
        <div class="menu-option--body-title">{{ 'audit.downloadCertificateFile' | translate }}</div>
        <div>{{ 'audit.signedFileContainingAutentiqueDigitalCertificate' | translate }}</div>
      </div>
    </a>
  </ng-container>
  <a class="menu-option" target="_blank" [ngClass]="{ 'd-none d-lg-inline-flex': !showDownloadOptions }" [href]="documentService.padesDocumentUrl(document)" *ngIf="document?.qualified">
    <div class="menu-option--icon"><i class="fal fa-file-code"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.downloadPAdESFile' | translate }}</div>
      <div>{{ 'audit.fileSignedWithEachSignatoryCertificatesInPAdESFormat' | translate }}</div>
    </div>
  </a>
  <a class="menu-option" target="_blank" [ngClass]="{ 'd-none d-lg-inline-flex': !showDownloadOptions }" [href]="document?.files?.original">
    <div class="menu-option--icon"><i class="fal fa-file-alt"></i></div>
    <div class="menu-option--body">
      <div class="menu-option--body-title">{{ 'audit.downloadOriginalFile' | translate }}</div>
      <div>{{ 'audit.fileSentForSignatureWithNoChangesOrSignatures' | translate }}</div>
    </div>
  </a>
</ng-template>
