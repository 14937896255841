<div class="dynamic-button-container">
  <h3>Autentique Button 'Generator'</h3>

  <!-- Button Type Selector -->
  <div>
    <label for="buttonType">Button Type:</label>
    <select id="buttonType" [(ngModel)]="buttonType" (change)="updateButton()">
      <option value="btn-filled">Filled</option>
      <option value="btn-outline">Outline</option>
      <option value="btn-text">Text</option>
    </select>
  </div>

  <!-- Button Type Variant -->
  <div *ngIf="['btn-filled', 'btn-text'].includes(buttonType)">
    <label for="buttonTypeVariant">Button Type Variant:</label>
    <select id="buttonTypeVariant" [(ngModel)]="buttonTypeVariant" (change)="updateButton()">
      <option value="">No Variant</option>
      <option value="btn-filled-danger" *ngIf="buttonType === 'btn-filled'">Filled Danger</option>
      <option value="btn-filled-cancel" *ngIf="buttonType === 'btn-filled'">Filled Cancel</option>
      <option value="btn-text-danger" *ngIf="buttonType === 'btn-text'">Text Danger</option>
    </select>
  </div>

  <!-- Button State Selector -->
  <div>
    <label for="buttonState">State:</label>
    <select id="buttonState" [(ngModel)]="buttonState" (change)="updateButton()">
      <option value="">Default</option>
      <option value="state-hover">Hover</option>
      <option value="state-focus">Focus</option>
      <option value="state-active">Active</option>
      <option value="state-disabled">Disabled</option>
    </select>
  </div>

  <!-- Button Size Selector -->
  <div>
    <label for="buttonSize">Size:</label>
    <select id="buttonSize" [(ngModel)]="buttonSize" (change)="updateButton()">
      <option value="btn-L">Large</option>
      <option value="btn-M">Medium</option>
      <option value="btn-S">Small</option>
      <option value="btn-XS">Extra Small</option>
    </select>
  </div>

  <!-- Start Icon Selector -->
  <div>
    <label for="iconStart">Start Icon:</label>
    <select id="iconStart" [(ngModel)]="iconStart" (change)="updateButton()">
      <option value="">No Icon</option>
      <option value="far fa-plus">Plus</option>
      <option value="far fa-check">Check</option>
      <option value="far fa-times">Times</option>
    </select>
  </div>

  <!-- End Icon Selector -->
  <div>
    <label for="iconEnd">End Icon:</label>
    <select id="iconEnd" [(ngModel)]="iconEnd" (change)="updateButton()">
      <option value="">No Icon</option>
      <option value="far fa-plus">Plus</option>
      <option value="far fa-check">Check</option>
      <option value="far fa-times">Times</option>
    </select>
  </div>

  <!-- Show or Hide Text -->
  <div>
    <label for="hasText">Include Text:</label>
    <input type="checkbox" id="hasText" [(ngModel)]="hasText" (change)="updateButton()" />
  </div>

  <!-- Dynamic Button -->
  <div>
    <button [class]="buttonClassShow" [disabled]="isDisabled">
      <i *ngIf="iconStart" [class]="iconStart"></i>
      <span *ngIf="hasText">Label</span>
      <i *ngIf="iconEnd" [class]="iconEnd"></i>
    </button>
  </div>

  <!-- Button Class Display -->
  <div class="value">
    <input class="value-show" readonly="" [value]="buttonClass" type="text" #inputValue />
    <a class="value-copy text-decoration-none" [ngxClipboard]="inputValue"></a>
  </div>
</div>
