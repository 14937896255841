<app-mobile-header-secondary title="Personalização de email"></app-mobile-header-secondary>

<div class="mobile mobile__page-in-construction">
  <div class="mobile__message mobile__message--red">
    <b>{{ 'external.availableForDesktop' | translate }}</b>
  </div>
</div>

<article class="configuration-item">
  <div class="row mb-4">
    <div class="col-8">
      <div class="configuration-title mb-1">
        {{ 'settings.emailCustomizationOf' | translate }} <span class="highlight">{{ currentUser?.organization?.name }}</span>
        <span class="pro" [attr.data-pro-text]="translateService.instant('shared.pro')"></span>
      </div>
      <div class="configuration-text">
        {{ 'settings.customizeEmailAppearanceAndTemplates' | translate }}
      </div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 d-flex justify-content-end" *ngIf="!isFormOpen">
      <button
        type="button"
        class="autentique-button"
        (click)="openCreateForm()"
        *ngIf="currentUser?.currentPermissions.change_appearances_oz"
        [disabled]="!currentUser?.subscription?.has_premium_features"
      >
        {{ 'settings.newTemplate' | translate }}
      </button>
    </div>
    <div class="col-4 d-flex justify-content-end" *ngIf="isFormOpen">
      <button type="button" class="btn btn-link text-danger" (click)="isFormOpen = false; isNewTemplate = false">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="saveSignatureEmail()" [disabled]="isLoading">{{ 'button.save' | translate }}</button>
    </div>
  </div>

  <ng-container *ngIf="currentUser && emailTemplates.length > 0 && !isFormOpen">
    <div class="row">
      <div class="col-12">
        <div class="template-card" *ngFor="let template of emailTemplates; let index = index" [class.disabled]="!currentUser?.subscription?.has_premium_features">
          <div
            class="template-card__head-dropdown"
            placement="bottom-right"
            ngbDropdown
            *ngIf="currentUser?.currentPermissions.change_appearances_oz && currentUser?.subscription?.has_premium_features"
          >
            <label [id]="'options-dropdown-' + index" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
            <div [attr.aria-labelledby]="'options-dropdown-' + index" ngbDropdownMenu>
              <button (click)="openEditForm(template)" ngbDropdownItem>{{ 'button.edit' | translate }}</button>
              <button (click)="deleteEmailTemplate(template)" [disabled]="isLoading" ngbDropdownItem>{{ 'button.exclude' | translate }}</button>
            </div>
          </div>
          <div class="template-card__head" (click)="currentUser?.currentPermissions.change_appearances_oz && currentUser?.subscription?.has_premium_features && openEditForm(template)">
            <div class="template-card__head-icon">
              <i class="fal fa-envelope"></i>
            </div>
          </div>
          <div class="template-card__body" (click)="currentUser?.currentPermissions.change_appearances_oz && currentUser?.subscription?.has_premium_features && openEditForm(template)">
            <div class="template-card__body-title">{{ template.name }}</div>
            <div class="template-card__body-type">
              {{ template.type === emailTemplateTypeEnum.Solicitation ? ('settings.signatureRequest' | translate) : ('settings.successfulSignature' | translate) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-3" *ngIf="!isLoading && !isLastPage">
      <button type="button" class="btn btn-link" (click)="loadEmailTemplates({ page: pageNumber + 1 })">{{ 'button.loadMore' | translate }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="currentUser && isFormOpen">
    <div class="configuration-container">
      <div class="configuration-content" *ngIf="currentUser">
        <form class="main-form" [formGroup]="form" (ngSubmit)="saveSignatureEmail()" novalidate>
          <!-- Para o submit rodar no Enter -->
          <button type="submit" class="d-none" [disabled]="form.invalid || isLoading"></button>
          <div class="edit" formGroupName="email">
            <div class="form">
              <div class="templateType">
                <label style="width: 390px; margin-right: 10px" class="field__holder" [appFormControlWrap]="form.get('email.name')">
                  <input type="text" class="field" formControlName="name" name="name" autocomplete="none" role="presentation" required />
                  <span class="field__placeholder">{{ 'settings.templateName' | translate }}</span>
                </label>
                <app-select
                  style="width: 330px"
                  placeholder="Template"
                  [disableSearch]="true"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="emailSelectedType"
                  (ngModelChange)="
                    form
                      .get('email')
                      .get('type')
                      .setValue($event);
                    changeDefault($event)
                  "
                  [options]="[
                    { key: emailTemplateTypeEnum.Solicitation, value: translateService.instant('settings.signatureRequest'), height: 46, fontSize: 16 },
                    { key: emailTemplateTypeEnum.SignatureCompleted, value: translateService.instant('settings.signatureSuccess'), height: 46, fontSize: 16 },
                    { key: emailTemplateTypeEnum.Completed, value: translateService.instant('settings.documentCompletion'), height: 46, fontSize: 16 }
                  ]"
                >
                </app-select>
              </div>
              <div class="configuration-item d-flex">
                <div class="configuration-mail-holder edit templateSelect">
                  <ng-container *ngFor="let language of languages" [formGroupName]="language.key">
                    <ng-container *ngIf="!isLoading && language.key == selectedLang">
                      <app-select placeholder="{{ 'profile.language' | translate }}" [disableSearch]="true" [options]="languages" [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedLang">
                      </app-select>
                      <app-select
                        *ngIf="form.get('email').get('type').value === emailTemplateTypeEnum.Solicitation"
                        placeholder="{{ 'settings.style' | translate }}"
                        [disableSearch]="true"
                        formControlName="template"
                        [options]="[
                          { key: 'padrao', value: translateService.instant('settings.default'), height: 46, fontSize: 16 },
                          { key: 'classico', value: translateService.instant('settings.classic'), height: 46, fontSize: 16 },
                          { key: 'empresarial', value: translateService.instant('settings.business'), height: 46, fontSize: 16 },
                          { key: 'clean', value: translateService.instant('settings.clean'), height: 46, fontSize: 16 }
                        ]"
                      >
                      </app-select>
                      <label
                        class="field__holder"
                        [appFormControlWrap]="
                          form
                            .get('email')
                            .get(language.key)
                            .get('subject')
                        "
                      >
                        <input type="text" class="field" name="subject" formControlName="subject" autocomplete="none" role="presentation" required />
                        <span class="field__placeholder">{{ 'settings.subject' | translate }}</span>
                        <ng-container
                          [ngTemplateOutlet]="emailVariablesDropdownTemplate"
                          [ngTemplateOutletContext]="{
                            input: form
                              .get('email')
                              .get(language.key)
                              .get('subject'),
                            index: 7,
                            model: 'subject'
                          }"
                        ></ng-container>
                      </label>
                      <label
                        class="field__holder"
                        [appFormControlWrap]="
                          form
                            .get('email')
                            .get(language.key)
                            .get('sender')
                        "
                      >
                        <input type="text" class="field" formControlName="sender" name="sender" autocomplete="none" role="presentation" required />
                        <span class="field__placeholder">{{ 'settings.senderName' | translate }}</span>
                        <ng-container
                          [ngTemplateOutlet]="emailVariablesDropdownTemplate"
                          [ngTemplateOutletContext]="{
                            input: form
                              .get('email')
                              .get(language.key)
                              .get('sender'),
                            index: 6,
                            model: 'sender'
                          }"
                        ></ng-container>
                      </label>
                      <hr class="greyLine" />
                      <label
                        class="field__holder textarea"
                        [appFormControlWrap]="
                          form
                            .get('email')
                            .get(language.key)
                            .get('title')
                        "
                      >
                        <textarea class="field" type="text" formControlName="title" autocomplete="none" role="presentation" required (input)="autoResize($event.target)"></textarea>
                        <span class="field__placeholder">{{ 'settings.header' | translate }}</span>
                        <ng-container
                          [ngTemplateOutlet]="emailVariablesDropdownTemplate"
                          [ngTemplateOutletContext]="{
                            input: form
                              .get('email')
                              .get(language.key)
                              .get('title'),
                            index: 5
                          }"
                        ></ng-container>
                      </label>
                      <label
                        class="field__holder textarea"
                        [appFormControlWrap]="
                          form
                            .get('email')
                            .get(language.key)
                            .get('text')
                        "
                      >
                        <textarea id="text" class="field" type="text" formControlName="text" autocomplete="none" role="presentation" required (input)="autoResize($event.target)"></textarea>
                        <span class="field__placeholder">{{ 'settings.defaultMessage' | translate }}</span>
                        <ng-container
                          [ngTemplateOutlet]="emailVariablesDropdownTemplate"
                          [ngTemplateOutletContext]="{
                            input: form
                              .get('email')
                              .get(language.key)
                              .get('text'),
                            index: 4
                          }"
                        ></ng-container>
                      </label>
                      <label
                        class="field__holder textarea"
                        [appFormControlWrap]="
                          form
                            .get('email')
                            .get(language.key)
                            .get('footer')
                        "
                      >
                        <textarea class="field" type="text" formControlName="footer" autocomplete="none" role="presentation" required (input)="autoResize($event.target)"></textarea>
                        <span class="field__placeholder">{{ 'settings.footer' | translate }}</span>
                        <ng-container
                          [ngTemplateOutlet]="emailVariablesDropdownTemplate"
                          [ngTemplateOutletContext]="{
                            input: form
                              .get('email')
                              .get(language.key)
                              .get('footer')
                          }"
                        ></ng-container>
                      </label>
                      <div class="d-flex mb-3" formArrayName="colors">
                        <app-color-input class="mr-3" formControlName="0"></app-color-input>
                        <app-color-input
                          formControlName="1"
                          *ngIf="
                            form
                              .get('email')
                              .get(language.key)
                              .get('template').value &&
                            templates[
                              form
                                .get('email')
                                .get(language.key)
                                .get('template').value
                            ].multiColor &&
                            form.get('email').get('type').value === emailTemplateTypeEnum.Solicitation
                          "
                        ></app-color-input>
                      </div>
                      <label class="btn btn-outline-primary mb-2">
                        <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectEmailImage($event.target['files'][0]); $any($event.target).value = ''" />
                        {{ 'settings.headerImage' | translate }}
                      </label>
                      <p
                        [hidden]="
                          !form
                            .get('email')
                            .get(language.key)
                            .get('image')
                            .hasError('server')
                        "
                        class="field__error-message mb-4"
                        *ngIf="form"
                      >
                        {{
                          form
                            .get('email')
                            .get(language.key)
                            .get('image')
                            .getError('server')
                        }}
                      </p>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="configuration-mail dick butt" *ngIf="currentUser && !isLoading">
                  <div class="configuration-mail-bar">
                    <div class="dot"></div>
                  </div>
                  <div class="configuration-mail-title forceDefaultColors">
                    <input
                      class="configuration-mail-title-field"
                      type="text"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="subject"
                      (ngModelChange)="subject = $event"
                      autocomplete="none"
                      role="presentation"
                    />
                  </div>
                  <div class="configuration-mail-signatory forceDefaultColors">
                    <input
                      class="configuration-mail-signatory-field"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="sender"
                      (ngModelChange)="sender = $event"
                      autocomplete="none"
                      role="presentation"
                    />
                  </div>
                  <div class="configuration-mail-body">
                    <app-signature-email-preview
                      [user]="currentUser"
                      [selectedLang]="selectedLang"
                      [emailType]="form.get('email').get('type').value"
                      [emailSettings]="form.get('email').get(selectedLang).value"
                    ></app-signature-email-preview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</article>

<ng-template #emailVariablesDropdownTemplate let-input="input" let-model="model" let-index="index">
  <div class="email-variables-dropdown" placement="bottom-right" [ngStyle]="{ 'z-index': index || 3 }" ngbDropdown>
    <label ngbDropdownToggle><i class="fal fa-brackets-curly"></i></label>
    <div ngbDropdownMenu>
      <button type="button" *ngFor="let variable of variables" (click)="addEmailVariableTo(variable, input, model)" ngbDropdownItem>
        {{ 'email_variable_' + variable | translate }}
      </button>
    </div>
  </div>
</ng-template>
