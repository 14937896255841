<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="formStep = FormSteps.permissions" *ngIf="formStep === FormSteps.name">
  <p class="centermodal__title">{{ 'settings.newGroup' | translate }}</p>

  <ng-container formGroupName="group">
    <div class="centermodal__form">
      <label class="field__holder" [appFormControlWrap]="form.get('group.name')">
        <input type="text" class="field" formControlName="name" autocomplete="none" role="presentation" required #nameInput />
        <p class="field__placeholder">{{ 'settings.groupName' | translate }}</p>
      </label>
    </div>

    <div class="centermodal__group mb-4">
      <div class="centermodal__group-title"></div>
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.accessAllModelsOrganization' | translate }}</span>
        <app-switch formControlName="overwrite_template"></app-switch>
      </div>
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.shareAllModelsGroup' | translate }}</span>
        <app-switch formControlName="overwrite_template_group"></app-switch>
      </div>
    </div>
  </ng-container>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.get('group').invalid">{{ 'button.forward' | translate }}</button>
  </div>
</form>

<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="formStep = FormSteps.appearance" *ngIf="formStep === FormSteps.permissions">
  <div class="centermodal__title">{{ 'settings.permissions' | translate }}</div>

  <ng-container formGroupName="permissions">
    <div class="centermodal__group">
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.overrideMemberPermissions' | translate }}</span>
        <app-switch formControlName="overwrite_permissions"></app-switch>
        <div class="textbox">
          <p class="centermodal__switch-description">{{ 'settings.permissionsOverrideByGroup' | translate }}</p>
        </div>
      </div>
    </div>

    <app-organization-permissions-form [form]="$any(form.get('permissions'))" [class.d-none]="!form.get('permissions.overwrite_permissions').value"></app-organization-permissions-form>
  </ng-container>

  <div class="centermodal__button-group mt-3">
    <button type="button" class="centermodal__button" (click)="formStep = FormSteps.name">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="form.get('permissions').invalid">{{ 'button.forward' | translate }}</button>
  </div>
</form>

<form class="modal-body appearance-form" novalidate [formGroup]="form" (ngSubmit)="create()" *ngIf="formStep === FormSteps.appearance">
  <div class="centermodal__title">{{ 'settings.appearance' | translate }}</div>

  <ng-container formGroupName="styles">
    <div class="centermodal__form">
      <div class="centermodal__switch border-0">
        <span class="centermodal__switch-name">{{ 'settings.overrideAppearanceSignatureEmail' | translate }}</span>
        <app-switch formControlName="overwrite_email" (ngModelChange)="onChangeOverwriteEmail($event)"></app-switch>
      </div>
      <app-select
        *ngFor="let item of emailTemplateItems"
        [placeholder]="'settings.' + item.label | translate"
        [disableSearch]="true"
        [disabled]="!isOverWritingEmail"
        [(ngModel)]="item.ngModel"
        [isLoading]="isLoading"
        [ngModelOptions]="{ standalone: true }"
        [options]="item.options"
        (ngModelChange)="onChangeSelectedTemplate()"
      >
      </app-select>
    </div>

    <div class="centermodal__form">
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.overrideNameCompanyAndCNPJMembers' | translate }}</span>
        <app-switch formControlName="overwrite_name" (ngModelChange)="onChangeOverwriteName($event)"></app-switch>
      </div>
      <div class="row" *ngIf="isOverwriteName">
        <div class="col-lg-6">
          <label class="field__holder" [appFormControlWrap]="form.get('styles.company')">
            <input type="text" class="field" formControlName="company" autocomplete="company" />
            <span class="field__placeholder">{{ 'profile.company' | translate }}</span>
          </label>
        </div>
        <div class="col-lg-6">
          <label class="field__holder" [appFormControlWrap]="form.get('styles.cnpj')">
            <input type="tel" class="field" formControlName="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" />
            <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="centermodal__button-group mt-4">
    <button type="button" class="centermodal__button" (click)="formStep = FormSteps.permissions">{{ 'button.back' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.get('styles').invalid">Criar grupo</button>
  </div>
</form>
