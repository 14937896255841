import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard, WorkaroundLogoutGuard } from '@app/guards';

import { DocumentsHomeComponent } from './documents-home/documents-home.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsTrashbinListComponent } from './documents-trashbin-list/documents-trashbin-list.component';
import { DocumentsGroupListComponent } from './documents-group-list/documents-group-list.component';
import { DocumentsOrganizationListComponent } from './documents-organization-list/documents-organization-list.component';
import { DocumentsOrganizationMemberListComponent } from './documents-organization-member-list/documents-organization-member-list.component';
import { FoldersListComponent } from './folders-list/folders-list.component';
import { FoldersGroupListComponent } from './folders-group-list/folders-group-list.component';
import { FoldersOrganizationListComponent } from './folders-organization-list/folders-organization-list.component';
import { FoldersOrganizationMemberListComponent } from './folders-organization-member-list/folders-organization-member-list.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { DocumentsNewComponent } from './documents-new/documents-new.component';
import { DocumentsEditComponent } from './documents-edit/documents-edit.component';
import { DocumentsFieldsEditComponent } from './documents-fields-edit/documents-fields-edit.component';
import { DocumentsShowComponent } from './documents-show/documents-show.component';
import { DocumentsTemplateComponent } from './documents-templates/documents-template.component';
import { DocumentsFillTemplateComponent } from './documents-templates/fill-template/fill-template.component';
import { WaitingroomComponent } from '../whitelabel/waitingroom/waitingroom.component';

const routes: Routes = [
  {
    path: 'documentos',
    component: DocumentsHomeComponent,
    canActivate: [AuthenticationGuard, WorkaroundLogoutGuard],
    children: [
      { path: '', redirectTo: '/documentos/todos', pathMatch: 'full' },
      { path: 'todos', component: DocumentsListComponent, data: { title: 'Meus documentos' } },
      { path: 'lixeira', component: DocumentsTrashbinListComponent, data: { title: 'Lixeira' } },
      { path: 'grupo', component: DocumentsGroupListComponent, data: { title: 'Documentos do grupo' } },
      { path: 'grupo/:groupId', component: DocumentsGroupListComponent, data: { title: 'Documentos do grupo' } },
      { path: 'organizacao', component: DocumentsOrganizationListComponent, data: { title: 'Documentos da organização' } },
      { path: 'organizacao/:memberId', component: DocumentsOrganizationMemberListComponent, data: { title: 'Documentos da organização' } }
    ]
  },
  {
    path: 'pastas',
    component: DocumentsHomeComponent,
    canActivate: [AuthenticationGuard],
    children: [
      { path: '', component: FoldersListComponent, pathMatch: 'full', data: { title: 'Minhas pastas' }, canActivate: [WorkaroundLogoutGuard] },
      { path: 'grupo', component: FoldersGroupListComponent, data: { title: 'Pastas do grupo' }, canActivate: [WorkaroundLogoutGuard] },
      { path: 'grupo/:groupId', component: FoldersGroupListComponent, data: { title: 'Pastas do grupo' }, canActivate: [WorkaroundLogoutGuard] },
      { path: 'organizacao', component: FoldersOrganizationListComponent, data: { title: 'Pastas da organização' }, canActivate: [WorkaroundLogoutGuard] },
      { path: 'organizacao/:memberId', component: FoldersOrganizationMemberListComponent, data: { title: 'Pastas da organização' }, canActivate: [WorkaroundLogoutGuard] },
      { path: ':folderId/documentos', component: DocumentsListComponent, data: { title: 'Documentos arquivados' } },
      { path: ':folderId/organizacao', component: DocumentsOrganizationListComponent, data: { title: 'Documentos compartilhados' } },
      { path: ':folderId/grupo', component: DocumentsOrganizationListComponent, data: { title: 'Documentos de pasta do grupo', context: 'Group' } },
      { path: ':folderId/grupo/:groupId/documentos', component: DocumentsOrganizationListComponent, data: { title: 'Documentos de pasta do grupo', context: 'Group' } },
      { path: ':folderId/organizacao/:memberId/documentos', component: DocumentsOrganizationMemberListComponent, data: { title: 'Documentos da organização' } }
    ]
  },
  {
    path: 'contatos',
    component: ContactsListComponent,
    canActivate: [AuthenticationGuard],
    children: [{ path: '', component: ContactsListComponent, pathMatch: 'full', data: { title: 'Meus Contatos' } }]
  },
  { path: 'documentos/novo', component: DocumentsNewComponent, data: { title: 'Criar documento' }, canActivate: [AuthenticationGuard] },
  { path: 'documentos/:id', component: DocumentsShowComponent, data: { title: 'Documento' } },
  // { path: 'documentos/:id/original', component: DocumentsOriginalComponent, data: { title: 'Baixar o documento original' } }, // Fazer redirecionamento de arquivo
  { path: 'documentos/:id/editar', component: DocumentsEditComponent, data: { title: 'Editar documento' } },
  { path: 'documentos/:id/campos', component: DocumentsFieldsEditComponent, data: { title: 'Editar campos do documento' } },
  { path: 'assinar/:id', component: DocumentsShowComponent, data: { title: 'Documento' } },
  { path: 'modelos/novo', component: DocumentsTemplateComponent, data: { title: 'Modelos' }, canActivate: [AuthenticationGuard] },
  { path: 'modelos/:id', component: DocumentsFillTemplateComponent, data: { title: 'Modelos' }, canActivate: [AuthenticationGuard] },
  { path: 'modelos/:id/editar', component: DocumentsTemplateComponent, data: { title: 'Modelos' }, canActivate: [AuthenticationGuard] },
  { path: 'waitingroom', component: WaitingroomComponent, data: { title: 'Loading' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule {}
