import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ErrorHandlerService, AuthenticationService, NotyService, ResetPasswordContext, WorkaroundService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-password-edit',
  templateUrl: './auth-password-edit.component.html',
  styleUrls: ['../auth.scss', './auth-password-edit.component.scss']
})
export class AuthPasswordEditComponent implements OnInit {
  form!: FormGroup;
  isLoading = false;

  constructor(
    public workaroundService: WorkaroundService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      email: this.route.snapshot.queryParams.email,
      token: this.route.snapshot.params.token,
      password: '',
      password_confirmation: ''
    } as ResetPasswordContext);
  }

  ngOnInit() {
    this.authenticationService.logout().subscribe();
  }

  resetPassword() {
    this.isLoading = true;
    this.form.markAllAsTouched();

    this.authenticationService
      .resetPassword(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.notyService.success(this.translateService.instant('notyService.passwordChangedSuccess'));
          this.authenticationService.logout().subscribe(() => this.router.navigate(['/entrar'], { replaceUrl: true, queryParams: { email: this.form.get('email').value } }));
        },
        error => this.errorHandlerService.handleValidation(this.form, error)
      );
  }
}
