import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import { Order, User } from '@app/models';
import { OrganizationInvoicesGQL, OrganizationInvoicesQueryVariables } from 'src/generated/graphql.public';
import {
  BuyCreditsGQL,
  BuyCreditsMutationVariables,
  CreditsPackGQL,
  PlansGQL,
  OrderGQL,
  CustomerGQL,
  CreateCustomerGQL,
  CreateCustomerMutationVariables,
  UpdateCustomerGQL,
  UpdateCustomerMutationVariables,
  CreateSubscriptionGQL,
  CreateSubscriptionMutationVariables,
  CancelSubscriptionGQL,
  UpdateSubscriptionGQL,
  UpdateSubscriptionMutationVariables,
  CreditLogsGQL,
  CreditLogsQueryVariables
} from 'src/generated/graphql.default';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private cachedCurrentOrder: Order;

  constructor(
    private httpClient: HttpClient,
    private creditsPackGQL: CreditsPackGQL,
    private buyCreditsGQL: BuyCreditsGQL,
    private plansGQL: PlansGQL,
    private orderGQL: OrderGQL,
    private customerGQL: CustomerGQL,
    private createCustomerGQL: CreateCustomerGQL,
    private updateCustomerGQL: UpdateCustomerGQL,
    private createSubscriptionGQL: CreateSubscriptionGQL,
    private cancelSubscriptionGQL: CancelSubscriptionGQL,
    private updateSubscriptionGQL: UpdateSubscriptionGQL,
    private organizationInvoicesGQL: OrganizationInvoicesGQL,
    private creditLogsGQL: CreditLogsGQL
  ) {}

  plans() {
    return this.plansGQL.fetch().pipe(
      throwOnGraphqlError(),
      map(response => response.data.plans)
    );
  }

  order(options: { useCache?: boolean } = {}) {
    return options.useCache && this.cachedCurrentOrder
      ? of(this.cachedCurrentOrder)
      : this.orderGQL.fetch(null).pipe(
          throwOnGraphqlError(),
          tap(response => (this.cachedCurrentOrder = response.data.order || null)),
          map(response => response.data.order)
        );
  }

  currentCustomer() {
    return this.customerGQL.fetch().pipe(
      throwOnGraphqlError(),
      map(response => response.data.customer)
    );
  }

  createCustomer(variables: CreateCustomerMutationVariables) {
    return this.createCustomerGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createCustomer)
    );
  }

  updateCustomer(variables: UpdateCustomerMutationVariables) {
    return this.updateCustomerGQL.mutate({ ...variables }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateCustomer)
    );
  }

  createSubscription(variables: CreateSubscriptionMutationVariables) {
    return this.createSubscriptionGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createSubscription)
    );
  }

  cancelSubscription() {
    return this.cancelSubscriptionGQL.mutate().pipe(
      throwOnGraphqlError(),
      tap(response => (this.cachedCurrentOrder = null)),
      map(response => response.data.cancelSubscription)
    );
  }

  updateSubscription(variables: UpdateSubscriptionMutationVariables) {
    return this.updateSubscriptionGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateSubscription)
    );
  }

  organizationInvoices(variables: OrganizationInvoicesQueryVariables) {
    return this.organizationInvoicesGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.organizationInvoices)
    );
  }

  credits() {
    return this.creditsPackGQL.fetch().pipe(
      throwOnGraphqlError(),
      map(response => response.data.creditsPack)
    );
  }

  buyCredits(variables: BuyCreditsMutationVariables) {
    return this.buyCreditsGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.buyCredits)
    );
  }

  subscriptionInvoiceExpirationDate(order: Order) {
    const dueDate = order?.latest_invoice?.due_date ? new Date(order?.latest_invoice?.due_date) : new Date();
    dueDate.setHours(23, 59, 59, 999);
    if (order?.payment_method && dueDate) {
      // dueDate.setDate(dueDate.getDate() + (order.payment_method === PaymentMethodsEnum.Boleto ? 10 : 0)); // Usuario pode pagar o boleto até 10 dias atrasados
      return dueDate;
    }
  }

  hasGatewaySubscriptionExpired(order: Order) {
    return order?.expires_at && new Date(order.expires_at) < new Date() && !order.active;
  }

  hasSubscriptionExpired(user: User) {
    return user?.subscription?.expires_at && new Date(user.subscription.expires_at) < new Date() && (user.subscription.name || '').toLowerCase() === 'free';
  }

  isSubscriptionActive(user: User) {
    return user?.subscription?.name && user.subscription.name.toLowerCase() !== 'free' && !this.hasSubscriptionExpired(user);
  }

  hasSubscriptionInvoiceExpired(order: Order) {
    return this.subscriptionInvoiceExpirationDate(order) && new Date() >= this.subscriptionInvoiceExpirationDate(order);
  }

  hasSubscriptionInvoicePending(order: Order) {
    const expirationDate = this.subscriptionInvoiceExpirationDate(order);
    return expirationDate && expirationDate >= new Date() && order.latest_invoice?.status === 'pending';
  }

  creditLogs(variables?: CreditLogsQueryVariables) {
    return this.creditLogsGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.creditLogs)
    );
  }
}
