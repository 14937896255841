import { Injectable } from '@angular/core';

import { AppService } from '@app/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class WorkaroundService {
  constructor(private appService: AppService) {}

  /**
   * This function is a workaround to avoid users from getting out of the documents page after they open a link to it from an email or somewhere else.
   * This workaround was initially made for and benefited only Pipefy.
   */
  hideDocumentBackButton(): boolean {
    return location.hostname === 'sign.pipefy.com' && !this.appService.previousUrl;
  }

  /**
   * This function is a workaround to avoid users from being redirected into the login or signup page, as they are forbidden.
   * This workaround was initially made for and benefited only Pipefy.
   */
  shouldAvoidLoginOrSignup(): boolean {
    return location.hostname === 'sign.pipefy.com';
  }
}
