import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';

import { Logger } from '@app/core/logger.service';
import { WorkaroundService } from '@app/services';

const log = new Logger('WorkaroundLogoutGuard');

@Injectable({
  providedIn: 'root'
})
export class WorkaroundLogoutGuard implements CanActivate {
  constructor(private router: Router, private workaroundService: WorkaroundService) {}

  canActivate(): Observable<boolean> {
    if (this.workaroundService.shouldAvoidLoginOrSignup()) {
      log.debug('This route was forbidden by a workaround, redirecting to custom logout page...');
      this.router.navigate(['/401'], { replaceUrl: true });

      return of(false);
    }

    return of(true);
  }
}
