<div
  class="item"
  [ngClass]="{
    'item--signed': documentService.isSignerSigned(signer),
    'item--reject': documentService.isSignerRejected(signer) && !hasMailError() && !hasCelphoneError(),
    'item--biometric-rejected': documentService.isSignerBiometricRejected(signer),
    'item--unapproved': documentService.isSignerUnapproved(signer),
    'item--mailError': hasMailError() || hasCelphoneError(),
    'item--waiting': documentService.isSignerPending(signer) && !documentService.isSignerUnapproved(signer)
  }"
>
  <div class="item-head">
    <span *ngIf="!signer.action?.name" class="item-head-contact">{{ 'signature.creator' | translate }}</span>
    <span *ngIf="signer.action?.name" class="item-head-contact">{{ documentService.translateRole(signer.action) }}</span>
    <span *ngIf="signer.action?.name && getStatusText(signer)" class="item-head-status">{{ 'documents.statusCard.' + getStatusText(signer) | translate }}</span>
    <div
      class="item-head-options"
      ngbDropdown
      display="dynamic"
      placement="bottom-right"
      *ngIf="isAllowedUser && signer.action && !signer.certificate && !documentService.isSignerSigned(signer); else signedDropdown"
    >
      <label [id]="'signer-dropdown-' + signer.public_id" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
      <div [attr.aria-labelledby]="'signer-dropdown-' + signer.public_id" ngbDropdownMenu>
        <ng-container *ngIf="!signer.user || documentAuthorId !== signer.user.id">
          <button *ngIf="signer.email && !documentService.isSignerSigned(signer)" (click)="updateClick.emit(signer)" ngbDropdownItem>
            <i class="fal fa-edit"></i>{{ 'signature.changeEmail' | translate }}
          </button>
          <button
            *ngIf="(signer.email || signer.user?.phone) && !documentService.isSignerSigned(signer)"
            (click)="$any($event.target).classList.add('disabled'); resendSignaturesClick.emit(signer)"
            ngbDropdownItem
          >
            <i class="fal fa-redo"></i>{{ 'signature.resend' | translate }}
          </button>
          <button *ngIf="(signer.email || signer.user?.phone) && documentService.isSignerPending(signer, { ignoreEmailErrors: true })" (click)="createLinkClick.emit(signer)" ngbDropdownItem>
            <i class="fal fa-link"></i>{{ 'signature.signatureLink' | translate }}
          </button>
          <ng-container *ngIf="signer.user?.birthday && signer.user?.cpf">
            <button type="button" *ngIf="(signer.email || signer.user.phone) && !documentService.isSignerSigned(signer)" (click)="consultCPFClick.emit(signer)" ngbDropdownItem>
              <i class="fal fa-id-card"></i>{{ 'signature.checkCPF' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="hasVerifiableData(signer)">
            <button type="button" (click)="verifyBiometryClick.emit(signer)" ngbDropdownItem><i class="fal fa-user"></i>{{ 'signature.biometricValidation' | translate }}</button>
          </ng-container>
        </ng-container>
        <button *ngIf="!documentService.isSignerSigned(signer)" (click)="deleteClick.emit(signer)" ngbDropdownItem>
          <i class="fal fa-user-minus"></i>{{ 'signature.removeSignatory' | translate }}
        </button>
      </div>
    </div>

    <ng-template #signedDropdown>
      <div
        class="item-head-options"
        ngbDropdown
        display="dynamic"
        placement="bottom-right"
        *ngIf="signer.user && documentService.isSignerSigned(signer) && isAllowedUser && ((signer.user?.birthday && signer.user?.cpf) || hasVerifiableData(signer))"
      >
        <label [id]="'signer-dropdown-' + signer.public_id" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>
        <div [attr.aria-labelledby]="'signer-dropdown-' + signer.public_id" ngbDropdownMenu>
          <ng-container *ngIf="signer.user?.birthday && signer.user?.cpf">
            <button type="button" (click)="consultCPFClick.emit(signer)" ngbDropdownItem><i class="fal fa-id-card"></i>{{ 'signature.checkCPF' | translate }}</button>
          </ng-container>
          <ng-container *ngIf="hasVerifiableData(signer)">
            <button type="button" (click)="verifyBiometryClick.emit(signer)" ngbDropdownItem><i class="fal fa-user"></i>{{ 'signature.biometricValidation' | translate }}</button>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <!--    <ng-template #signedDropdown2>-->
    <!--      <div class="item-head-options" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="signer.certificate">-->
    <!--        <label [id]="'signer-dropdown-' + signer.public_id" ngbDropdownToggle><i class="fal fa-ellipsis-v"></i></label>-->
    <!--        <div [attr.aria-labelledby]="'signer-dropdown-' + signer.public_id" ngbDropdownMenu>-->
    <!--          <button *ngIf="signer.certificate" (click)="certificateClick.emit(signer)" ngbDropdownItem><i class="fal fa-file-certificate"></i>{{ 'signature.certificate' | translate }}</button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </ng-template>-->
  </div>

  <div class="item-content">
    <app-autenticats
      class="item-content-avatar"
      [seed]="(signer.certificate?.email || '' | lowercase) || signer.email || signer.user?.name || signer.name || signer.configs?.overwrite_name || signer.user?.phone"
      [forceAutenticat]="forceAutenticat"
    ></app-autenticats>
    <div class="item-content-data" *ngIf="!signer.certificate">
      <div class="item-content-name">
        {{ signer.configs?.overwrite_name || signer.user?.name || signer.name || signer.email || signer.user?.phone }}
        <i class="fal fa-shield-check" [ngbTooltip]="tooltipSignatureVerificationSigned" *ngIf="findVerification(signer)?.verified_at"></i>
      </div>
      <div class="item-content-corp" *ngIf="canShowCompany(signer)">{{ userService.getCompany(signer.user) }}</div>
      <div class="item-content-id" *ngIf="signer.user?.cpf && signer.action?.name">
        {{ signer && !documentService.isSignerPending(signer) ? signer.user.cpf : cpfMask(signer.user.cpf) }}
      </div>

      <div class="item-content-date" *ngIf="signer.configs?.overwrite_date; else defaultBirthdate">
        {{ dateService.normalizeDate(signer.configs?.overwrite_date) }}
      </div>
      <ng-template #defaultBirthdate>
        <div class="item-content-date" *ngIf="signer.user?.birthday && signer.action?.name">
          {{ signer && !documentService.isSignerPending(signer) ? dateService.normalizeDate(signer.user.birthday) : birthdayMask(dateService.normalizeDate(signer.user.birthday)) }}
        </div>
      </ng-template>

      <div class="configuration-value mt-2" *ngIf="!signer.email && signer.link?.id && documentService.isSignerPending(signer) && isAllowedUser">
        <input type="text" class="configuration-value-show" [value]="appService.signerLink(signer.link)" readonly #webhookInputElement />
        <div class="configuration-value-copy" (click)="copySignatureLink()" [ngxClipboard]="webhookInputElement"></div>
      </div>

      <div class="item-content-message" *ngIf="signer.rejected?.reason">{{ 'signature.rejected' | translate }} <span [innerHTML]="signer.rejected.reason | nl2br"></span></div>

      <div class="item-content-links">
        <a class="item-content-link email" [href]="'mailto:' + signer.email" *ngIf="signer.email"><i class="fal fa-envelope"></i> {{ signer.email }}</a>
        <a
          class="item-content-link phone"
          [ngClass]="{ credits__content: findVerification(signer, SecurityVerificationEnum.Sms) }"
          href="javascript:void(0)"
          *ngIf="!signer.email && signer.user?.phone"
        >
          <i class="fal fa-phone"></i> {{ signer.user.phone | phone$ | async }}
        </a>
      </div>
    </div>
    <div class="item-content-data" *ngIf="signer.certificate">
      <div class="item-content-name">{{ signer.certificate.name }}</div>
      <div class="item-content-id" *ngIf="signer.certificate.document">{{ formatCpfCnpj(signer.certificate.document) }}</div>
      <div class="item-content-cert" *ngIf="signer.certificate.document">
        {{ isCpf(signer.certificate.document) ? ('profile.cpf' | translate) : ('profile.cnpj' | translate) }} {{ certificateTypes[signer.certificate.type] }}
      </div>

      <div class="item-content-links">
        <a class="item-content-link email" [href]="'mailto:' + (signer.certificate.email | lowercase)" *ngIf="signer.certificate.email">
          <i class="fal fa-envelope"></i> {{ signer.certificate.email | lowercase }}
        </a>
      </div>
    </div>
  </div>
  <div class="item-info-holder">
    <div class="item-info" [class.active]="step.isActive" *ngFor="let step of signer | toSignatureSteps: { documentAuthorId: documentAuthorId }">
      <div class="item-info-icon">
        <i class="fal fa-check" *ngIf="step.status === SignatureStepStatus.success"></i>
        <i
          class="fal fa-times"
          style="color:#d0a85d"
          *ngIf="
            step.status === SignatureStepStatus.bounce ||
            step.status === SignatureStepStatus.deferred ||
            step.status === SignatureStepStatus.blocked ||
            step.status === SignatureStepStatus.dropped ||
            step.status === SignatureStepStatus.spamreport ||
            step.status === SignatureStepStatus.cellSMS ||
            step.status === SignatureStepStatus.cellWhats
          "
        ></i>
        <i class="fal fa-times" *ngIf="step.status === SignatureStepStatus.failed"></i>
        <i class="fal fa-exclamation-circle" *ngIf="step.status === SignatureStepStatus.unapproved"></i>
        <i
          class="fal fa-clock"
          *ngIf="
            ![
              SignatureStepStatus.cellSMS,
              SignatureStepStatus.cellWhats,
              SignatureStepStatus.success,
              SignatureStepStatus.failed,
              SignatureStepStatus.deferred,
              SignatureStepStatus.blocked,
              SignatureStepStatus.dropped,
              SignatureStepStatus.spamreport,
              SignatureStepStatus.bounce,
              SignatureStepStatus.unapproved
            ].includes($any(step.status))
          "
        ></i>
      </div>
      <div class="item-info-content">
        <div class="item-info-title">
          <ng-container *ngIf="step.type === SignatureStepType.creation">{{ 'signature.createdDocument' | translate }}</ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.emailDelivery">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting && isWaitingSortable()">{{ 'signature.awaitingOtherSignatories' | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting && !isWaitingSortable()">
              {{ 'signature.deliverySending.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success">
              {{ 'signature.deliveryReceived.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.failed && !hasMailError()">
              {{ 'signature.deliveryNotReceived.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.cellWhats">{{ 'signature.notReceivedWhatsApp' | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.cellSMS">{{ 'signature.notReceivedSMS' | translate }}</ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.openEmail">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">
              {{ 'signature.deliveryNotOpenedYet.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success">
              {{ 'signature.deliveryOpened.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.toSee">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">{{ 'signature.notViewedYet' | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success">{{ 'signature.openedDocument' | translate }}</ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.toSign">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">{{ 'signature.notYetSubject.' + (signer.action.name | lowercase) | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success || step.status === SignatureStepStatus.unapproved">
              {{ 'signature.actionDocumentSubject.' + (signer.action.name | lowercase) | translate }}
              <ng-container *ngIf="!signer.certificate">
                <div
                  class="item-info-label nofield"
                  [routerLink]="[router.url + '/campos']"
                  [ngbTooltip]="tooltipSignatureInvisible"
                  *ngIf="isAllowedUser && step.status === SignatureStepStatus.success && !isSignatureVisible()"
                >
                  <i class="fal fa-eye-slash"></i> {{ 'signature.invisibleSignature' | translate }}
                </div>
                <div class="item-info-label active" [ngbTooltip]="tooltipSignatureVisible" *ngIf="isAllowedUser && step.status === SignatureStepStatus.success && isSignatureVisible()">
                  <i class="fal fa-signature"></i> {{ 'signature.visibleSignature' | translate }}
                </div>
                <div class="item-info-label biometry" [ngbTooltip]="tooltipSignatureVerificationSigned" *ngIf="findVerification(signer, SecurityVerificationEnum.Sms) && !hasVerifiableData(signer)">
                  <i class="fal fa-shield-check"></i>
                  {{ 'signature.additionalVerification' | translate }}
                </div>
                <div
                  class="item-info-label biometry"
                  [ngClass]="{ unapproved: documentService.isSignerUnapproved(signer) }"
                  [ngbTooltip]="documentService.isSignerUnapproved(signer) ? null : tooltipSignatureVerificationSigned"
                  (click)="verifyBiometryClick.emit(signer)"
                  style="cursor: pointer"
                  *ngIf="(isAllowedUser || !documentService.isSignerUnapproved(signer)) && hasVerifiableData(signer)"
                >
                  <ng-container *ngIf="!documentService.isSignerUnapproved(signer)"><i class="fal fa-shield-check"></i> {{ 'signature.additionalVerification' | translate }}</ng-container>
                  <ng-container *ngIf="documentService.isSignerUnapproved(signer)">{{ 'signature.additionalVerificationClickHere' | translate }}</ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.failed">
              {{ documentService.isSignerBiometricRejected(signer) ? ('signature.biometricRejected' | translate) : ('signature.rejectedDocument' | translate) }}
              <ng-container *ngIf="!signer.certificate">
                <div class="item-info-label biometry" [ngbTooltip]="tooltipSignatureVerificationRejected" *ngIf="findVerification(signer, SecurityVerificationEnum.Sms) && !hasVerifiableData(signer)">
                  <i class="fal fa-shield-check"></i>
                  {{ 'signature.additionalVerification' | translate }}
                </div>
                <div
                  class="item-info-label biometry failed"
                  [ngbTooltip]="tooltipSignatureVerificationRejected"
                  (click)="verifyBiometryClick.emit(signer)"
                  style="cursor: pointer"
                  *ngIf="hasVerifiableData(signer) && this.filterVerifications(signer, this.documentService.allowedManualVerifications)[0]?.verified_at"
                >
                  <i class="fal fa-shield-check"></i> {{ 'signature.additionalVerification' | translate }}
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="!signer.rejected">
          <div
            *ngIf="
              step.status === SignatureStepStatus.bounce ||
              step.status === SignatureStepStatus.deferred ||
              step.status === SignatureStepStatus.blocked ||
              step.status === SignatureStepStatus.dropped ||
              step.status === SignatureStepStatus.spamreport
            "
            class="item-info-title"
          >
            {{ 'signature.deliveryNotReceived.' + (signer.delivery_method | lowercase) | translate }} ({{ returnEmailEvent() }})
          </div>
          <div *ngIf="step.status === SignatureStepStatus.deferred">
            <p class="emailError__smallDescription">{{ 'signature.notReceivedEmailTemporaryError' | translate }}</p>
            <div class="emailError__reasonMessage" [innerHTML]="returnEmailErrorMessage(signer.email_events?.reason, 'Deferred')"></div>
            <p class="emailError__smallDescription">
              {{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}. Vamos tentar reenviar o email automaticamente em alguns minutos. O status será atualizado se funcionar.
            </p>
          </div>
          <div *ngIf="step.status === SignatureStepStatus.bounce">
            <p class="emailError__smallDescription">{{ 'signature.notReceivedEmailError' | translate }}</p>
            <div class="emailError__reasonMessage" [innerHTML]="returnEmailErrorMessage(signer.email_events.reason, 'Bounce')"></div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>
          <div *ngIf="step.status === SignatureStepStatus.dropped">
            <p class="emailError__smallDescription">{{ 'signature.blockedRecipient' | translate }}</p>
            <div class="emailError__reasonMessage" [innerHTML]="returnEmailErrorMessage(signer.email_events?.reason, 'Dropped')"></div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>
          <div *ngIf="step.status === SignatureStepStatus.blocked">
            <p class="emailError__smallDescription">{{ 'signature.blockedRecipientEmailServer' | translate }}</p>
            <div class="emailError__reasonMessage" [innerHTML]="returnEmailErrorMessage(signer.email_events?.reason, 'Blocked')"></div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>
          <div *ngIf="step.status === SignatureStepStatus.spamreport">
            <p class="emailError__smallDescription">{{ 'signature.spamReport' | translate }}</p>
            <div class="emailError__reasonMessage" [innerHTML]="returnEmailErrorMessage(signer.email_events?.reason, 'Spamreport')"></div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>

          <div *ngIf="step.status === SignatureStepStatus.cellWhats">
            <p class="emailError__smallDescription">{{ 'signature.whatsappError' | translate }}</p>
            <div class="emailError__reasonMessage">{{ signer.email_events.reason }}</div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>

          <div *ngIf="step.status === SignatureStepStatus.cellSMS">
            <p class="emailError__smallDescription">{{ 'signature.carrierError' | translate }}</p>
            <div class="emailError__reasonMessage">{{ signer.email_events.reason }}</div>
            <p class="emailError__smallDescription">{{ 'signature.at' | translate }} {{ signer.email_events.refused | dateTime }}</p>
          </div>
        </ng-container>

        <div class="item-info-descr">
          <ng-container *ngIf="step.type === SignatureStepType.emailChange">
            <ng-container *ngFor="let email of step.previousEmails; let index = index">
              <ng-container *ngIf="index === 0">{{ 'signature.createdWithEmail' | translate: { email: email } }}</ng-container>
              <ng-container *ngIf="index > 0"><br />{{ 'signature.emailChangedTo' | translate: { email: email } }}</ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.creation"> {{ 'signature.at' | translate }} {{ signer.created_at | dateTime }} </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.emailDelivery">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting && isWaitingSortable()">{{ 'signature.signatureOrderActive' | translate }}</ng-container>
            <ng-container
              *ngIf="step.status === SignatureStepStatus.waiting && !isWaitingSortable() && (signer.delivery_method !== DeliveryMethodEnum.DeliveryMethodSms || !signer.email_events?.sent)"
            >
              {{ 'signature.deliveryStillBeingSent.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <!-- <ng-container *ngIf="step.status === SignatureStepStatus.deferred">
              O serviço de emails do cliente está sobrecarregado e adiou o recebimento do email, vamos continuar tentando enviá-lo. Assim que o email for recebido o status será atualizado.
            </ng-container> -->
            <!-- <ng-container *ngIf="step.status === SignatureStepStatus.failed">
              Erro ao enviar. {{ returnDeliveryMethodAddressText(signer) }} está correto?
              <i *ngIf="signer.email_events?.reason" class="fal fa-lg fa-info-circle" [title]="signer.email_events?.reason"></i><br />
            </ng-container> -->
            <ng-container *ngIf="step.status === SignatureStepStatus.success || step.status === SignatureStepStatus.failed">
              {{ 'signature.at' | translate }} {{ signer.email_events?.delivered || signer.email_events?.sent || signer.email_events?.refused || signer.viewed?.created_at | dateTime }}
            </ng-container>
            <ng-container *ngIf="signer.email_events?.sent && !signer.email_events?.delivered && signer.delivery_method === DeliveryMethodEnum.DeliveryMethodSms">
              {{ 'signature.theSMSWasReceivedByTheCarrier' | translate }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.openEmail">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">
              {{ 'signature.deliveryNotYetOpened.' + (signer.delivery_method | lowercase) | translate }}
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success">{{ 'signature.at' | translate }} {{ signer.email_events?.opened || signer.viewed?.created_at | dateTime }}</ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.toSee">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">{{ 'signature.notViewedYetDocument' | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success">
              <ng-container>{{ 'signature.at' | translate }} {{ signer.viewed?.created_at | dateTime }}</ng-container>
              <ng-container *ngIf="signer.viewed?.ip">
                {{ 'signature.byIP' | translate }} {{ signer.viewed.ip.match(':') ? '[' + signer.viewed.ip + ']' : signer.viewed.ip
                }}<ng-container *ngIf="signer.viewed?.port">:{{ signer.viewed.port }}</ng-container>
              </ng-container>
              <ng-container *ngIf="signer.viewed?.geolocation">
                &nbsp;<i class="fal fa-info-circle" [ngbTooltip]="tooltipGeo" placement="left"></i>
                <ng-template #tooltipGeo>
                  <div class="tooltip__descr">
                    <strong>{{ 'signature.IPLocation' | translate }}</strong
                    ><br />
                    {{ signer.viewed.geolocation.city }}, {{ signer.viewed.geolocation.stateISO }} - {{ signer.viewed.geolocation.country }}
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="step.type === SignatureStepType.toSign">
            <ng-container *ngIf="step.status === SignatureStepStatus.waiting">{{ 'signature.signNotDone.' + (signer.action.name | lowercase) | translate }}</ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.success || step.status === SignatureStepStatus.unapproved">
              <ng-container *ngFor="let signedStep of [signer.signed || signer.signed_unapproved]">
                <ng-container>{{ 'signature.at' | translate }} {{ signedStep?.created_at | dateTime }}</ng-container>
                <ng-container *ngIf="signedStep?.ip">
                  {{ 'signature.byIP' | translate }} {{ signedStep.ip.match(':') ? '[' + signedStep.ip + ']' : signedStep.ip
                  }}<ng-container *ngIf="signedStep?.port">:{{ signedStep.port }}</ng-container>
                </ng-container>
                <ng-container *ngIf="signedStep?.geolocation">
                  &nbsp;<i class="fal fa-info-circle" [ngbTooltip]="tooltipGeo" placement="left"></i>
                  <ng-template #tooltipGeo>
                    <div class="tooltip__descr">
                      <strong>{{ 'signature.IPLocation' | translate }}</strong
                      ><br />
                      {{ signedStep.geolocation.city }}, {{ signedStep.geolocation.stateISO }} - {{ signedStep.geolocation.country }}
                    </div>
                  </ng-template>
                  <ng-container *ngIf="findVerification(signer)">
                    <div>
                      {{ 'signature.additionalVerificationPer' | translate }}
                      <strong>
                        <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.Sms)">
                          <span [innerHTML]="'signature.additionalVerificationSMS' | translate"></span>
                          <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.Live) && !documentService.isSignerBiometricApproved(signer)">, </ng-container>
                          <ng-container
                            *ngIf="filterVerifications(signer, [SecurityVerificationEnum.Upload, SecurityVerificationEnum.PfFacial]).length > 0 || documentService.isSignerBiometricApproved(signer)"
                          >
                            {{ 'signature.and' | translate }}
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!documentService.isSignerBiometricApproved(signer); else elseNotBiometricApproved">
                          <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.Upload)">
                            <span [innerHTML]="'signature.additionalVerificationUpload' | translate"></span>
                          </ng-container>
                          <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.Live)">
                            <span [innerHTML]="'signature.additionalVerificationLive' | translate"></span>
                          </ng-container>
                          <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.PfFacial)">
                            <span [innerHTML]="'signature.additionalVerificationPfFacial' | translate"></span>
                          </ng-container>
                          <ng-container *ngIf="findVerification(signer, SecurityVerificationEnum.Manual)">
                            <span [innerHTML]="'signature.additionalVerificationManual' | translate"></span>
                          </ng-container>
                        </ng-container>
                        <ng-template #elseNotBiometricApproved>
                          <span [innerHTML]="'signature.additionalVerificationManualApproved' | translate"></span>
                        </ng-template>
                      </strong>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="step.status === SignatureStepStatus.failed">
              <ng-container *ngFor="let rejectedStep of [signer.rejected || signer.biometric_rejected]">
                <ng-container>{{ 'signature.at' | translate }} {{ rejectedStep?.created_at | dateTime }}</ng-container>
                <ng-container *ngIf="rejectedStep?.ip">
                  {{ 'signature.byIP' | translate }} {{ rejectedStep.ip.match(':') ? '[' + rejectedStep.ip + ']' : rejectedStep.ip
                  }}<ng-container *ngIf="rejectedStep?.port">:{{ rejectedStep.port }}</ng-container>
                </ng-container>
                <ng-container *ngIf="rejectedStep?.geolocation">
                  &nbsp;<i class="fal fa-info-circle" [ngbTooltip]="tooltipGeo" placement="left"></i>
                  <ng-template #tooltipGeo>
                    <div class="tooltip__descr">
                      <strong>{{ 'signature.IPLocation' | translate }}</strong
                      ><br />
                      {{ rejectedStep.geolocation.city }}, {{ rejectedStep.geolocation.stateISO }} - {{ rejectedStep.geolocation.country }}
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipSignatureVisible>
  <div class="tooltip__descr">{{ 'signature.signatoryVisibleSignature' | translate }}</div>
</ng-template>
<ng-template #tooltipSignatureInvisible>
  <div class="tooltip__descr">{{ 'signature.signatoryInvisibleSignature' | translate }}</div>
</ng-template>
<ng-template #tooltipSignatureVerificationSigned>
  <div class="tooltip__descr">{{ 'signature.documentWithAdditionalVerification' | translate }}</div>
</ng-template>
<ng-template #tooltipSignatureVerificationRejected>
  <div class="tooltip__descr">{{ 'signature.documentRejectedByAdditionalVerification' | translate }}</div>
</ng-template>
