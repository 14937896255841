import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';

import { I18nService, untilDestroyed } from '@app/core';
import { DocumentInput, Folder, FooterEnum, LocaleInput, ReminderEnum, SignatureAppearanceEnum, User } from '@app/models';
import { AppService, DateService, UserService, WhitelabelService } from '@app/services';
import { FoldersListingService } from '@app/pages/documents/shared/folders-listing.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectOption } from '@app/shared';

@Component({
  selector: 'app-document-configs-form',
  templateUrl: './document-configs-form.component.html',
  styleUrls: ['./document-configs-form.component.scss']
})
export class DocumentConfigsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() document: DocumentInput;
  @Input() validationErrors: { [k: string]: any };
  @Input() isNewDocument = false;
  @Input() isQualified = false;
  @Output() documentChange = new EventEmitter<DocumentInput>();
  currentUser: User;
  isLockedCC = false;
  isLockedReplyTo = false;
  showReminder = false;
  showExpiration = false;
  showDeadline = false;
  showNewSignatureStyle = false;
  showAuditPage = true;
  isUpdatedUserData = true;
  deadline: { date?: string; time?: string } = {};
  cc = '';
  replyTo = '';
  isCollapsedEmailConfigs: boolean;
  isCollapsedAppearanceConfigs: boolean;
  isCollapsedReminderConfigs: boolean;
  isCollapsedMiscellaneousConfigs: boolean;
  isCollapsedLanguage: boolean;
  folder: Folder;

  readonly ReminderEnum = ReminderEnum;
  readonly FooterEnum = FooterEnum;
  readonly SignatureAppearanceEnum = SignatureAppearanceEnum;
  readonly timezones: SelectOption[] = [];
  readonly dateFormat: SelectOption[] = [];

  constructor(
    public appService: AppService,
    public translateService: TranslateService,
    public whitelabelService: WhitelabelService,
    private userService: UserService,
    private foldersListingService: FoldersListingService,
    private i18nService: I18nService,
    private dateService: DateService
  ) {
    this.timezones = this.i18nService.timezonesWithGMT || null;
    this.dateFormat = this.i18nService.dateFormatOptions || null;
  }

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.userService.getCurrentUser().subscribe(user => {
      if (user) {
        this.document.message = this.document.message || user.settings.email.text;
        if (this.isNewDocument) {
          this.document.locale = {
            country: this.document?.locale?.country || user.locale.country,
            language: this.document?.locale?.language || user.locale.language,
            timezone: this.document?.locale?.timezone || user.locale.timezone,
            date_format: this.document?.locale?.date_format || user.locale.date_format
          } as LocaleInput;
          this.document.configs = { notification_signed: true, notification_finished: false, lock_user_data: false, signature_appearance: null, ...this.document.configs };
        }
        if (user.subscription.has_premium_features && this.isNewDocument) {
          this.switchChangeExpiration();
        }
        this.toggleSwitches();
      }
    });
    if (this.appService.documentParams.show_audit_page === false && this.isNewDocument) {
      this.showAuditPage = false;
      this.document.show_audit_page = false;
    }
    if (!this.isNewDocument) {
      this.showAuditPage = this.document.show_audit_page;
    }
    if (!this.document.qualified) {
      this.isUpdatedUserData = !this.document.configs?.lock_user_data;
    }

    this.isCollapsedEmailConfigs = typeof this.appService.documentSections.email === 'boolean' ? this.appService.documentSections.email : true;
    this.isCollapsedAppearanceConfigs = typeof this.appService.documentSections.appearance === 'boolean' ? this.appService.documentSections.appearance : true;
    this.isCollapsedReminderConfigs = typeof this.appService.documentSections.reminder === 'boolean' ? this.appService.documentSections.reminder : true;
    this.isCollapsedMiscellaneousConfigs = typeof this.appService.documentSections.miscellaneous === 'boolean' ? this.appService.documentSections.miscellaneous : true;
    this.isCollapsedLanguage = typeof this.appService.documentSections.language === 'boolean' ? this.appService.documentSections.language : true;
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isQualified) {
      this.selectNewSignatureStyle(this.document.new_signature_style);
    }
    if ((changes.document || {}).currentValue) {
      if (changes.document.currentValue.cc !== (changes.document.previousValue || {}).cc) {
        const documentInput = changes.document.currentValue;
        this.cc = ((documentInput.cc || []) as DocumentInput['cc']).map(cc => cc.email).join(', ');
      }
      if (changes.document.currentValue.reply_to !== (changes.document.previousValue || {}).reply_to) {
        this.replyTo = changes.document.currentValue.reply_to;
      }
      if (changes.document.currentValue !== changes.document.previousValue) {
        if ((changes.document.currentValue.expiration || {}).notify_at) {
          changes.document.currentValue.expiration.notify_at = this.dateService.normalizeDate(new Date(changes.document.currentValue.expiration.notify_at).toLocaleDateString('pt-BR'));
        }
        if (changes.document.currentValue.deadline_at) {
          this.showDeadline = true;
          const deadlineAt = new Date(changes.document.currentValue.deadline_at);
          this.deadline.date = this.dateService.normalizeDate(deadlineAt.toLocaleDateString('pt-BR'));
          this.deadline.time = deadlineAt.toLocaleTimeString('pt-BR').substring(0, 5);
        } else {
          this.showDeadline = false;
        }
        this.toggleSwitches();
      }
    }
  }

  parsedDocumentInput(document?: DocumentInput): DocumentInput {
    this.appService.documentSections = {
      email: this.isCollapsedEmailConfigs,
      appearance: this.isCollapsedAppearanceConfigs,
      reminder: this.isCollapsedReminderConfigs,
      miscellaneous: this.isCollapsedMiscellaneousConfigs,
      language: this.isCollapsedLanguage
    };
    const documentClone = cloneDeep(document || this.document);
    documentClone.name = (documentClone.name || '-').trim(); // Back-end dá um erro zoado, vazio não adianta. Se o valor tiver menos de 3 caracteres dá um erro interpretável.
    if (documentClone.qualified) {
      documentClone.sortable = true;
      this.deleteDocumentAttribute(documentClone.configs || {}, 'lock_user_data', false);
      // this.deleteDocumentAttribute(documentClone, 'footer', null);
      // this.deleteDocumentAttribute(documentClone.configs || {}, 'signature_appearance', null);
    }
    if (!documentClone.footer) {
      this.deleteDocumentAttribute(documentClone, 'footer', null);
    }
    if (!documentClone.refusable) {
      this.deleteDocumentAttribute(documentClone, 'stop_on_rejected', true);
    }
    if (!this.showReminder || !this.currentUser.subscription.has_premium_features) {
      this.deleteDocumentAttribute(documentClone, 'reminder', this.currentUser.subscription.has_premium_features ? null : undefined);
    }
    if (!this.showDeadline || !(this.deadline.date && this.deadline.time && this.deadline.date.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/) && this.deadline.time.match(/[0-9]{2}:[0-9]{2}/))) {
      this.deleteDocumentAttribute(documentClone, 'deadline_at', null);
    } else {
      const deadlineAt = new Date(
        this.dateService
          .normalizeDate(this.deadline.date)
          .split('/')
          .reverse()
          .join('-') +
          ' ' +
          this.deadline.time
      ).toJSON();
      if (deadlineAt) {
        documentClone.deadline_at = deadlineAt;
      } else {
        this.deleteDocumentAttribute(documentClone, 'deadline_at', null);
      }
    }
    if (
      !this.showExpiration ||
      !this.currentUser.subscription.has_premium_features ||
      !((documentClone.expiration || {}).notify_at || '').match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/) ||
      !parseInt((documentClone.expiration || {}).days_before as any)
    ) {
      this.deleteDocumentAttribute(documentClone, 'expiration', this.currentUser.subscription.has_premium_features ? null : undefined);
    } else {
      documentClone.expiration.notify_at = this.dateService.normalizeDate(documentClone.expiration.notify_at);
    }
    if (!this.currentUser.subscription.has_premium_features) {
      this.deleteDocumentAttribute(documentClone, 'cc');
      this.deleteDocumentAttribute(documentClone, 'reply_to');
      this.deleteDocumentAttribute(documentClone, 'configs');
    } else {
      documentClone.cc = (this.cc || '')
        .split(/,|\s/)
        .map(email => email.trim())
        .filter(email => !!email)
        .map(email => ({ email }));
      if (documentClone.cc.length === 0) {
        this.deleteDocumentAttribute(documentClone, 'cc', null);
      }

      documentClone.reply_to = this.replyTo;
      if (documentClone?.reply_to?.length === 0) this.deleteDocumentAttribute(documentClone, 'reply_to', null);

      if (!documentClone.qualified) {
        documentClone.configs.lock_user_data = !this.isUpdatedUserData;
      }
    }
    if (documentClone.locale.country !== 'BR') {
      documentClone.ignore_cpf = true;
    }
    return documentClone;
  }

  switchChangeFooter() {
    this.document.footer = this.document.footer ? FooterEnum.Bottom : null;
  }

  switchChangeReminder() {
    if (this.showReminder && !this.document.refusable) {
      this.isCollapsedMiscellaneousConfigs = false;
    }
    this.document.refusable = this.showReminder || this.document.refusable;
    this.document.reminder = this.document.reminder ? this.document.reminder : ReminderEnum.Weekly;
  }

  switchChangeExpiration() {
    this.document.expiration = this.document.expiration ? this.document.expiration : ({} as DocumentInput['expiration']);
    this.document.expiration.days_before = this.document.expiration.days_before ? this.document.expiration.days_before : 7;
    const notifyAt = this.document.expiration.notify_at ? this.document.expiration.notify_at : new Date(new Date().getTime() + 31536000000).toLocaleDateString('pt-BR');
    this.document.expiration.notify_at = this.dateService.normalizeDate(notifyAt);
  }

  switchSignatureAppearance() {
    this.document.configs.signature_appearance = this.document.configs.signature_appearance ? SignatureAppearanceEnum.Handwriting : null;
  }

  switchChangeDeadline() {
    const thirtyDaysFromNow = new Date(new Date().getTime() + 2592000000);
    this.deadline.date = this.dateService.normalizeDate(this.deadline.date || thirtyDaysFromNow.toLocaleDateString('pt-BR'));
    this.deadline.time = this.deadline.time || '23:59';
    this.document.deadline_at = this.document.deadline_at || thirtyDaysFromNow.toJSON();
  }

  selectNewSignatureStyle(value: boolean) {
    if (this.isQualified) {
      if (!value) {
        this.showAuditPage = value;
        this.document.show_audit_page = value;
        this.document.footer = null;
      }
      this.showNewSignatureStyle = value;
      this.document.new_signature_style = value;
    }
    // this.documentChange.emit(this.document);
  }

  switchAuditPage() {
    if (this.document.qualified) {
      if (this.showNewSignatureStyle) {
        this.document.show_audit_page = !this.document.show_audit_page;
      }
    } else {
      if (!this.document.qualified) {
        this.document.show_audit_page = !this.document.show_audit_page;
      }
    }
  }

  openFoldersModal() {
    this.foldersListingService.openFoldersModal([], null, true).subscribe((folder: Folder) => {
      this.folder = folder;
    });
  }

  private toggleSwitches(user?: User) {
    const currentUser = user || this.currentUser;
    if (this.isNewDocument && currentUser && currentUser.subscription.has_premium_features) {
      this.isLockedCC = !!this.appService.lockedCC;
      this.isLockedReplyTo = !!this.appService.lockedReplyTo;
      this.showReminder = this.appService.documentParams.reminder !== undefined || false;
      this.showExpiration = this.appService.documentParams.expiration.days_before !== undefined || this.appService.documentParams.expiration.notify_at !== undefined || false;
    } else if (currentUser && !this.isNewDocument) {
      this.showReminder = !!(this.document || {}).reminder;
      this.showExpiration = !!((this.document || {}).expiration || {}).notify_at;
    }
  }

  private deleteDocumentAttribute(document: any, attribute: string, value?: any) {
    if (this.isNewDocument || value === undefined) {
      delete document[attribute];
    } else {
      document[attribute] = value;
    }
  }
}
