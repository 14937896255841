import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthenticationMethod } from '@app/models';
import { SmsLoginModalService } from '@app/shared/sms-login-modal/sms-login-modal.service';
import { PhoneService } from './phone.service';

declare const msal: any;

interface OauthResult {
  accessToken: string;
  user: { name: string };
  phoneNumber?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  constructor(private ngZone: NgZone, private smsLoginModalService: SmsLoginModalService, private phoneService: PhoneService) {}

  login(type: AuthenticationMethod, additionalInfo?: any): Observable<OauthResult> {
    if (type === AuthenticationMethod.Google) {
      return this.loginGoogle(additionalInfo);
    } else if (type === AuthenticationMethod.Facebook) {
      return this.loginFacebook();
    } else if (type === AuthenticationMethod.Microsoft) {
      return this.loginMicrosoft();
    } else if ([AuthenticationMethod.Sms, AuthenticationMethod.Whatsapp].includes(type)) {
      return this.loginPhone(type, additionalInfo);
    } else {
      return of(null);
    }
  }

  private loginFacebook() {
    return new Observable<OauthResult>(subscriber => {
      FB.init({
        appId: environment.oauthFacebook,
        cookie: false,
        xfbml: true,
        autoLogAppEvents: true,
        version: 'v3.3'
      });

      FB.login(
        response => {
          this.ngZone.run(() => {
            if (response.authResponse) {
              subscriber.next({ accessToken: response.authResponse.accessToken, user: { name: '' } });
              subscriber.complete();
            } else {
              subscriber.error(response);
            }
          });
        },
        { scope: 'public_profile,email', return_scopes: true }
      );
    });
  }

  private loginGoogle(additionalInfo: any) {
    return new Observable<OauthResult>(subscriber => {
      if (additionalInfo && additionalInfo.credential) {
        subscriber.next({ accessToken: additionalInfo.credential, user: { name: additionalInfo.content?.name || '' } });
        subscriber.complete();
      } else {
        subscriber.error();
      }
    });
  }

  private loginMicrosoft() {
    return new Observable<OauthResult>(subscriber => {
      new msal.PublicClientApplication({
        auth: {
          clientId: environment.oauthMicrosoft,
          authority: 'https://login.microsoftonline.com/common',
          redirectUri: location.origin
        },
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1
        }
      })
        .loginPopup({ scopes: ['User.Read'] })
        .then((response: any) => {
          subscriber.next({ accessToken: response.accessToken, user: { name: response.account.name } });
          subscriber.complete();
        })
        .catch((error: any) => subscriber.error(error));
    });
  }

  private loginPhone(type: AuthenticationMethod, additionalInfo?: any) {
    return new Observable<OauthResult>(subscriber => {
      const phoneNumber = additionalInfo?.phoneNumber && additionalInfo.phoneNumber.trim();
      const forceUserCreation = delete (additionalInfo || {}).forceUserCreation || false;
      (phoneNumber && additionalInfo?.phoneCode
        ? this.phoneService[type === AuthenticationMethod.Whatsapp ? 'submitWhatsappVerificationCode' : 'submitSmsVerificationCode'](phoneNumber, additionalInfo.phoneCode).pipe(
            map(response => ({ accessToken: response.access_token, phoneNumber }))
          )
        : this.smsLoginModalService.open({ type, forceUserCreation })
      ).subscribe(
        data => {
          subscriber.next({ accessToken: data.accessToken, user: { name: '' }, phoneNumber: data.phoneNumber });
          subscriber.complete();
        },
        error => subscriber.error(error),
        () => (subscriber.closed ? null : subscriber.error())
      );
    });
  }
}
