import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InvoicesComponent } from './invoices.component';

const routes: Routes = [{ path: 'notas/:id', component: InvoicesComponent, data: { title: 'Notas fiscais' } }]; // 'invoices/:id'

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoicesRoutingModule {}
