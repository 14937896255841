<app-auth-container [backToRegister]="true">
  <img class="autentique-logo smartphone" src="/assets/logo-and-text-white.png" alt="" />
  <img class="autentique-logo tablet" src="/assets/Autentique.svg" alt="" />
  <p class="auth__title">{{ 'auth.signWith' | translate }}</p>
  <div class="auth__methods forceDefaultColors">
    <app-google-oauth
      class="auth__methods-option"
      context="signin"
      uxMode="popup"
      type="standard"
      shape="rectangular"
      theme="outline"
      text="signin_with"
      size="large"
      width="235"
      logoAlignment="left"
      (callback)="login(AuthenticationMethod.Google, $event)"
      *ngIf="!isElectron"
    >
    </app-google-oauth>
    <button type="button" class="auth__methods-option face" [disabled]="isLoading" (click)="login(AuthenticationMethod.Facebook)" *ngIf="!isElectron">
      <i class="fab fa-facebook"></i>
      <span>{{ 'auth.signWithFacebook' | translate }}</span>
    </button>
    <button type="button" class="auth__methods-option microsoft" [disabled]="isLoading" (click)="login(AuthenticationMethod.Microsoft)" *ngIf="!isElectron">
      <figure class="icon hide" style='background-image: url("/assets/microsoft.svg")'></figure>
      <span>{{ 'auth.signWithMicrosoft' | translate }}</span>
    </button>
  </div>
  <p class="auth__alternative" *ngIf="!isElectron">{{ 'auth.or' | translate }}</p>
  <form class="field__form" [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <app-switch-tabs
      *ngIf="loginStep != LoginStepEnum.Password && loginStep != LoginStepEnum.SmsCode"
      name="type"
      [options]="[
        [true, translateService.instant('auth.mail')],
        [false, translateService.instant('auth.phone')]
      ]"
      [(ngModel)]="loginByMail"
      [ngModelOptions]="{ standalone: true }"
    ></app-switch-tabs>
    <ng-container *ngIf="loginByMail">
      <label class="field__holder" [appFormControlWrap]="form.get('email')">
        <input type="email" class="field" formControlName="email" name="email" autocomplete="on" [appDisableInput]="loginStep !== LoginStepEnum.Login" appValidEmail required />
        <span class="field__placeholder">{{ 'auth.mailAddress' | translate }}</span>
      </label>
    </ng-container>
    <ng-container *ngIf="!loginByMail">
      <label class="field__holder" [appFormControlWrap]="form.get('phone')">
        <app-phone-number
          inputClass="field"
          formControlName="phone"
          [appDisableInput]="[LoginStepEnum.SmsCode, LoginStepEnum.WhatsappCode].includes($any(loginStep))"
          name="phone"
          autocomplete="tel-national"
          appValidPhone
          required
        ></app-phone-number>
        <span class="field__placeholder">{{ 'documents.yourPhoneNumber' | translate }}</span>
        <small [hidden]="!form.get('phone').value || (!form.get('phone').hasError('phone') && !form.get('phone').hasError('userExistsError'))" class="text-danger">{{
          'error_invalid_phone_number' | translate
        }}</small>
      </label>
    </ng-container>
    <label class="field__holder" [appFormControlWrap]="form.get('password')" *ngIf="loginStep === LoginStepEnum.Password">
      <input type="password" class="field" formControlName="password" name="password" autocomplete="current-password" required appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'auth.password' | translate }}</span>
    </label>
    <ng-container *ngIf="[LoginStepEnum.SmsCode, LoginStepEnum.WhatsappCode].includes($any(loginStep))">
      <label class="field__holder" [appFormControlWrap]="form.get('phoneCode')">
        <input type="text" class="field" formControlName="phoneCode" name="phoneCode" autocomplete="one-time-code" required />
        <span class="field__placeholder">{{ 'auth.confirmCode' | translate }}</span>
      </label>
      <div class="auth__descr auth__descr--small">
        <div>
          {{
            'auth.' + (loginStep === LoginStepEnum.WhatsappCode ? 'codeSendWhatsapp' : 'codeSendSms')
              | translate
                : {
                    phoneValue: form.get('phone').value | phone$ | async
                  }
          }}
        </div>
        <div class="text-center whatsDiv">
          {{ 'auth.didNotReceiveCode' | translate }}
          <button
            type="button"
            class="btn btn-link btnAnimatedBackground"
            [disabled]="isLoading || isSmsTimeout"
            (click)="sendPhoneCode('sms', form.get('phone').value, true); timeOutForWhatsappVerification()"
          >
            {{ 'auth.clickHereResend' | translate }}
          </button>
        </div>
        <div class="text-center whatsDiv">
          {{ 'auth.orTryWhatsappIn' | translate }}
          <button
            type="button"
            class="btn btn-link whatsDiv__btn btnAnimatedBackground"
            [ngClass]="{ whatsAppColor: !whatsAppTimer.isCountdownInProgress() }"
            [disabled]="isLoading || isSmsTimeout || whatsAppTimer.isCountdownInProgress()"
            (click)="sendPhoneCode('whats', form.get('phone').value, true); timeOutForWhatsappVerification()"
          >
            <span class="whatsDiv__whatsapp">
              <i class="fab fa-whatsapp"></i>&nbsp; WhatsApp &nbsp;{{ (whatsAppTimer.isCountdownInProgress() ? 'documents.in' : 'documents.clickingHere') | translate }}
              <div>&nbsp;{{ whatsAppTimer.isCountdownInProgress() ? formattedTime : '' }}</div>
            </span>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="field__error" *ngIf="loginStep === LoginStepEnum.Register">
      <i class="fal fa-exclamation-triangle"></i>
      {{ 'auth.' + (isValidEmail(form.get('email').value) ? 'accountNotFoundEMail' : 'accountNotFoundPhone') | translate }}
    </div>
    <div class="submit-holder">
      <button type="button" class="submit back" [disabled]="isLoading" (click)="loginStep = LoginStepEnum.Login" *ngIf="loginStep !== LoginStepEnum.Login">
        {{ 'button.back' | translate }}
      </button>
      <button
        type="submit"
        class="submit"
        [disabled]="
          isLoading ||
          (loginStep === LoginStepEnum.SmsCode && form.get('phoneCode').invalid) ||
          (loginStep === LoginStepEnum.Password && form.get('password').invalid) ||
          (loginStep === LoginStepEnum.WhatsappCode && form.get('phoneCode').invalid)
        "
      >
        {{ ('auth.loginStep' | translate)[loginStep] || ('button.forward' | translate) }}
      </button>
    </div>
  </form>
  <a routerLink="/recuperar-senha" queryParamsHandling="preserve" class="auth__recover">{{ 'auth.forgotPasswordQuestion' | translate }}</a>
  <a routerLink="/cadastro" queryParamsHandling="preserve" class="auth__link">{{ 'auth.dontHaveAccRegistryHere' | translate }}</a>
</app-auth-container>
