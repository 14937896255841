import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { User } from '@app/models';

export interface ModalPublicProperties {
  currentUser: User;
}

@Component({
  selector: 'app-alert-credits-modal',
  templateUrl: './alert-credits-limit-modal.component.html',
  styleUrls: ['./alert-credits-limit-modal.component.scss']
})
export class AlertCreditsLimitModalComponent {
  @Input() currentUser: User;
  isLoading = false;

  constructor(public modal: NgbActiveModal, private router: Router) {}

  goToPayment(): void {
    this.router.navigate(['/perfil/credits']);
    this.modal.close();
  }

  isChildOrganization() {
    return !!this.currentUser?.organization.parent?.id;
  }
}
