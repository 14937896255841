import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StylesheetRoutingModule } from './stylesheet-routing.module';
import { StylesheetComponent } from './stylesheet.component';
import { BtnComponent } from '@app/pages/stylesheet/shared/btn/btn.component';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [StylesheetComponent, BtnComponent],
  imports: [StylesheetRoutingModule, CommonModule, FormsModule, ClipboardModule]
})
export class StylesheetModule {}
