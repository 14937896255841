<form class="modal-body" novalidate [formGroup]="form" (ngSubmit)="update()">
  <div class="centermodal__title">
    {{ 'settings.appearanceOf' | translate }} <b>{{ group.name }}</b>
  </div>
  <ng-container formGroupName="styles">
    <div class="centermodal__form">
      <div class="centermodal__switch border-0">
        <span class="centermodal__switch-name">{{ 'settings.overrideAppearanceSignatureEmail' | translate }}</span>
        <app-switch formControlName="overwrite_email" (ngModelChange)="onChangeOverwriteEmail($event)"></app-switch>
      </div>
      <app-select
        *ngFor="let item of emailTemplateItems"
        [placeholder]="'settings.' + item.label | translate"
        [disableSearch]="true"
        [disabled]="!isOverWritingEmail"
        [(ngModel)]="item.ngModel"
        [isLoading]="isLoading"
        [ngModelOptions]="{ standalone: true }"
        [options]="item.options"
        (ngModelChange)="onChangeSelectedTemplate()"
      >
      </app-select>
    </div>
    <div class="centermodal__form">
      <div class="centermodal__switch">
        <span class="centermodal__switch-name">{{ 'settings.overrideCompanyAndCNPJMembers' | translate }}</span>
        <app-switch formControlName="overwrite_name" (ngModelChange)="onChangeOverwriteName($event)"></app-switch>
      </div>
      <ng-container *ngIf="isOverwriteName">
        <label class="field__holder" [appFormControlWrap]="form.get('styles.company')">
          <input type="text" class="field" formControlName="company" autocomplete="company" />
          <span class="field__placeholder">{{ 'profile.company' | translate }}</span>
        </label>
        <label class="field__holder" [appFormControlWrap]="form.get('styles.cnpj')">
          <input type="tel" class="field" formControlName="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" />
          <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
        </label>
      </ng-container>
    </div>
  </ng-container>
  <div class="centermodal__button-group mt-4">
    <button type="button" class="centermodal__button" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || form.invalid">{{ 'button.save' | translate }}</button>
  </div>
</form>
