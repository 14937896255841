import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { BannedComponent } from './banned/banned.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  { path: '401', component: LogoutComponent, data: { title: 'Você foi deslogado' } },
  { path: '404', component: NotFoundComponent, data: { title: 'Página não encontrada' } },
  { path: '423', component: BannedComponent, data: { title: 'Conta bloqueada' } },
  { path: '503', component: MaintenanceComponent, data: { title: 'Em manutenção' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HttpErrorsRoutingModule {}
